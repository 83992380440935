.add-to-calendar-container {
  position: relative;
}

.add-to-calendar-container svg {
  cursor: pointer;
}

.calendar-item-container {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  flex-direction: column;
  background: var(--nav-sub-bg);
  padding: 10px 16px;
  border-radius: 12px;
  border: 0;
  z-index: 1000;
}

.add-to-calendar-container .calendar-item-overlay {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.calendar-item-container.show {
  display: flex;
}

.calendar-item-container a {
  cursor: pointer;
  color: white;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  padding: 9px 0;
  border-bottom: 1px solid #87A1BC;
  display: flex;
  align-items: center;
  gap: 10px;
}

.calendar-item-container a:hover {
  color: #1283FF;
}

.calendar-item-container .calendar-item-icon {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-item-container a:last-child {
  border-bottom: 0;
}

.calendar-item-container svg,
.calendar-item-container img {
  height: 30px;
}
