.community-sale-container {
  flex: 1;
  /* background-color: var(--bg-primary); */
  /* padding: 50px 0; */
}

@media (min-width: 1200px) {
  .community-sale-container .container {
    max-width: 1420px;
  }
}

.community-sale-card {
  background-color: var(--race-bg);
  /* box-shadow: 0px 0px 10px 0px #A0CEFF33; */
  box-shadow: 0px 0px 8.199999809265137px 0px var(--community-sale-card-shadow);
  padding: 35px 40px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  color: var(--race-text);
  font-family: 'Roboto';
}

.community-sale-card.whitelist-card {
  background-color: var(--waitlist-card-bg);
  box-shadow: 0px 0px 10px 0px var(--waitlist-card-box-shadow);
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  padding: 13px;
}

.community-sale-card.yellow-card {
  background-color: #F8EB52;
  color: #000000;
  box-shadow: 0px 0px 10px 0px var(--waitlist-card-box-shadow);
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  padding: 13px;
}

.community-sale-card h2 {
  font-family: 'Roboto';
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 10px;
}

.community-sale-card h3 {
  font-family: 'Chyletoon-Condensed';
  font-size: 42px;
  margin-bottom: 10px;
}

.community-sale-card h4 {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
}

.community-sale-card p {
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 17px;
  margin-bottom: 0;
}

.community-sale-card .separator {
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: var(--chicken-card-comp-border);
}

.community-sale-card .separator.vertical {
  width: 1px;
  height: inherit;
  margin: 0 20px;
}

.community-sale-card .sale-controller {
  background-color: var(--table-btn);
  padding: 7px 10px;
  border-radius: 6px;
  display: flex;
  margin: 15px 0;
}

.community-sale-card .btn {
  box-shadow: 0px 0px 7px 0px #1283FF !important;
  background-color: #1283FF;
  border: 3px solid #1283FF;
  border-radius: 6px;
  color: white;
  cursor: pointer;
}

.community-sale-card .btn:hover {
  border-color: #7FBCFF;
}

.community-sale-card .btn:active {
  background-color: #7FBCFF;
  border-color: #7FBCFF;
  box-shadow: none !important;
}

.community-sale-card .btn.disabled {
  background-color: var(--table-btn);
  border-color: var(--table-btn);
  box-shadow: none !important;
  color: #728EAC;
}

.community-sale-card .connect-btn {
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  padding: 15px;
  border-radius: 12px;
  margin-top: 40px;
}

.community-sale-card .refund-btn {
  padding: 18px 20px;
  width: 100%;
  border-radius: 12px;
  margin-top: 40px;
}

.community-sale-card .opensea-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  gap: 5px;
}
.community-sale-card .opensea-btn img {
  width: 26px;
}

.community-sale-card .refund-btn.disabled {
  background-color: var(--table-btn);
  box-shadow: none !important;
  color: #728EAC;
}

.sale-controller .btn {
  width: 32px;
  height: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.sale-controller input {
  background-color: transparent;
  border: 0;
  outline: none;
  flex: 1;
  width: 100%;
  text-align: center;
  color: var(--race-text);
  font-size: 22px;
  font-weight: 700;
}

.community-sale-card table {
  width: 100%;
  font-size: 17px;
  font-weight: 300;
}

.community-sale-card table tr td,
.community-sale-card table tr th {
  padding: 11px 0;
  font-weight: 300;
  border-bottom: 1px solid var(--chicken-card-comp-border);
}

.community-sale-card table tr:last-child td {
  border-bottom: 0;
}

.community-sale-card table tr td:last-child,
.community-sale-card table tr th:last-child {
  text-align: right;
}

.community-sale-card .progress-bar-container {
  box-shadow: 0px 0px 12.199999809265137px 0px #459EFF8A;
  padding: 2px;
  border-radius: 30px;
}

.community-sale-card .progress-bar-container .progress-bar {
  height: 11px;
  border-radius: 30px;
  background-color: #C1DFFF;
  position: relative;
}

.dark .community-sale-card .progress-bar-container .progress-bar {
  background-color: #162431;
}

.community-sale-card .progress-bar-container .progress-step {
  height: 100%;
  width: 100px;
  background-color: #459EFF;
}

.community-sale-card ul {
  list-style: none;
  padding-left: 30px;
}

.community-sale-card ul li {
  position: relative;
  font-weight: 300;
  font-size: 17px;
  padding: 10px 0;
  border-bottom: 1px solid var(--chicken-card-comp-border);
}

.community-sale-card ul li:last-child {
  border-bottom: 0;
}

.community-sale-card ul li:before {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #1283FF;
  left: -20px;
  top: 17px;
}

.community-sale-card .invite-code-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}

.community-sale-card .invite-code {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--table-btn);
  border: 1px solid var(--table-btn);
  padding: 10px 15px;
  border-radius: 12px;
  gap: 10px;
  cursor: pointer;
}

.community-sale-card .invite-code:hover {
  border-color: #1283FF;
}

.community-sale-card .invite-code .copy-icon {
  padding-left: 10px;
  border-left: 2px solid var(--race-text);
  cursor: pointer;
}

.community-sale-card .eligibility-check {
  display: flex;
  column-gap: 20px;
}

.community-sale-card .eligibility-check .eligibility-check-input {
  background-color: var(--table-btn);
  border: 1px solid var(--table-btn);
  flex: 1;
  border-radius: 12px;
  padding: 12px 20px;
}

.community-sale-card .eligibility-check .eligibility-check-input:focus-within {
  border-color: #1283FF;
}

.community-sale-card .eligibility-check .eligibility-check-input input {
  background-color: transparent;
  border: 0;
  outline: none;
  color: var(--race-text);
  font-size: 18px;
  font-weight: 300;
}
.community-sale-card .eligibility-check .eligibility-check-input input::placeholder {
  color: #728EAC;
}

.dark .community-sale-card .eligibility-check .eligibility-check-input input::placeholder {
  color: #A7A7A7;
}

.community-sale-card .eligibility-check .btn {
  max-width: 185px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.community-sale-card .waitlist {
  background-color: var(--table-btn);
  padding: 20px 18px;
  border-radius: 12px;
  text-decoration: underline;
  font-size: 19px;
}

.community-sale-card .claim-chickens {
  border: 1px solid var(--chicken-card-comp-border);
  border-radius: 12px;
  font-size: 17px;
}

.community-sale-card .claim-chickens .claim-chickens-header {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid var(--chicken-card-comp-border);
}

.community-sale-card .claim-chickens .claim-chickens-body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}


.community-sale-card .claim-chickens .claim-chicken {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid var(--chicken-card-comp-border);
  padding: 10px 5px;
  row-gap: 15px;
}

.community-sale-card .claim-chickens .claim-chicken:last-child {
  border-right: 0;
}

.community-sale-card .claim-chickens .claim-chicken img {
  width: 80%;
  border-radius: 6px !important;
  overflow: hidden;
}

.community-sale-card .claim-chickens .claim-chicken .claim-chicken-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.community-sale-card .claim-chickens .claim-chicken .claim-chicken-info svg {
  cursor: pointer;
}

.community-sale-card .raffle-won {
  font-size: 18px;
  color: #4FD87E;
}

.community-sale-card .raffle-lost {
  font-size: 18px;
  color: red;
}

.community-sale-card .presale-live {
  font-family: 'roboto';
  font-size: 22px;
  color: #4FD87E;
  padding: 0 20px;
  position: relative;
}

.community-sale-card  .presale-over {
  font-family: 'roboto';
  font-size: 22px;
  color: red;
  padding-left: 20px;
  position: relative;
}

.community-sale-card .presale-live::after {
  content: '';
  position: absolute;
  bottom: 7px;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #4FD87E;
}

@media (max-width: 767px) {
  .community-sale-card .invite-code-container {
    flex-direction: column;
    gap: 10px;
  }

  .community-sale-card .claim-chickens .claim-chickens-body {
    grid-template-columns: repeat(2, 1fr);
  }

  .community-sale-card .eligibility-check {
    flex-direction: column;
    border-radius: 12px;
    overflow: hidden;
  }

  .community-sale-card .eligibility-check .eligibility-check-input {
    border-radius: 0;
  }

  .community-sale-card .eligibility-check .btn {
    box-shadow: none !important;
    width: 100%;
    max-width: inherit;
    border-radius: 0;
    padding: 15px;
  }

  .community-sale-card .reserve-lots {
    flex-direction: column;
  }

  .community-sale-card .reserve-lots .separator {
    width: 100%;
    height: 1px;
    margin: 20px 0;
  }
}
