.jewels-modal-div .enter-shop {
  border: none;
  width: 100%;
  background: #1283ff;
  height: 53px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 1px;
}
.jewels-modal .close-modal-btn {
  position: absolute;
  right: -13px;
  top: -9px;
  background: transparent;
  outline: none;
  color: #fff;
  border: none;
}
.modal.show .jewels-modal.modal-dialog {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
