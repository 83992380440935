
.mynav {
  /* background: var(--nav-bg); */
  border: 0;
  border-radius: 0;
  margin-bottom: 0;
  padding: 18px 0;
  position: relative;
}

.mynav .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 10px;
}

.mynav img.logo {
  width: auto;
}

.mynav .navbar-nav li a {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  color: var(--nav-text) !important;
  cursor: pointer;
  padding: 0px !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.mynav .navbar-nav li a::after {
  content: none;
}

.mynav .navbar-nav li a:hover {
  /* background: none !important;
  */
}

.mynav.navbar .collapse.header-collapse-center {
  flex: 1;
  justify-content: center;
}
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
  background: none !important;
}

.mynav .navbar-nav {
  display: flex;
  align-items: center;
  gap: 13px;
}

.mynav.navbar .collapse {
  flex-grow: initial;
}

.mynav.navbar .container {
  padding-right: 15px;
  padding-left: 15px;
  gap: 20px;
}

.mynav .navbar-toggler {
  border: none;
}

.mynav .logo-mobile {
  display: none;
}

.mynav .navbar-nav .dropdown-menu {
  background: var(--nav-sub-bg);
  padding: 16px;
  border-radius: 12px;
  border: 0;
}

.mynav .navbar-nav .dropdown.show .dropdown-toggle svg {
  transform: rotate(180deg);
}

.mynav .navbar-nav .dropdown-menu .dropdown-item {
  color: white !important;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  padding: 9px 0 !important;
  border-bottom: 1px solid #87A1BC;
}

.mynav .navbar-nav .dropdown-menu .dropdown-item:hover {
  color: #1283FF !important;
}

.dark .mynav .navbar-nav .dropdown-menu .dropdown-item {
  border-color: #1E3C58;
}

.mynav .navbar-nav .dropdown-menu .dropdown-item:first-child {
  padding-top: 0px !important;
}

.mynav .navbar-nav .dropdown-menu .dropdown-item:last-child {
  padding-bottom: 0px !important;
  border-bottom: 0px;
}

.navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-item:hover {
  background: var(--nav-sub-bg);
}

.navbar-nav #dropdown-flags {
  color: var(--text);
}


.pricing-section {
  display: flex;
  gap: 10px;
  align-items: center;
}

.pricing-section .price-items-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 20px;
  flex-wrap: wrap;
}

.pricing-section .pricing-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.price-items {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.theme-switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 24px;
  margin-bottom: 0px;
  margin: 5px 10px;
  outline: none;
}


.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
  outline: none;
}

.pricing-section .pricing-item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--nav-text);
  font-family: 'Roboto';
  font-size: 19px;
  font-weight: 300;
  text-decoration: none;
  max-height: 47px;
  padding: 10px 20px !important;
  border-radius: 50px;
  border: 1px solid #1E3C58;
  box-shadow: 0px 0px 12px 0px #459EFF33;
  background: var(--nav-bg);
}

.pricing-section .pricing-item:hover {
  background-color: #F8EB52;
  border: 1px solid #FFFFFF;
  color: #000000;
}

.pricing-section .pricing-item:active {
  background: #F8EB5299;
  border: 1px solid #FFFFFF;
  color: #000000;
}

.pricing-section .price-items.hidden .pricing-item p {
  filter: blur(6px);
}

.pricing-section .pricing-item-line {
  height: 20px;
  width: 1px;
  background-color: #728EAC;
}

.pricing-section .pricing-item:last-child {
  border-right: 0;
}

.pricing-section .pricing-item p {
  margin-bottom: 0px;
  font-size: 19px;
}

.pricing-section .pricing-item img {
  width: 20px;
  margin-left: 5px;
}

.pricing-section .pricing-item.gray img {
  filter: grayscale(100%);
}

.pricing-section .btn-login {
  height: 47px;
  width: 230px;
  border-radius: 50px;
  font-size: 23px;
  background-color: #F8EB52 !important;
  border: 1px solid #1E3C58 !important;
  box-shadow: 0px 0px 12.2px 0px #459EFF8A !important;
  color: #000000 !important;
  line-height: 30px;
  padding: 0 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-section .btn-login:hover {
  border-color: #fff !important;
}

.pricing-section .btn-login:active {
  border-color: #fff !important;
  border-width: 5px !important;
}

.pricing-section .pricing-btn {
  font-size: 24px;
  color: #728EAC;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 47px;
  height: 47px;
  border-radius: 50px;
  border: 1px solid #1E3C58;
  box-shadow: 0px 0px 12px 0px #459EFF33;
  background: var(--nav-bg);
}

.pricing-section .pricing-btn:hover {
  background-color: #F8EB52;
  border: 1px solid #FFFFFF;
  color: #000000;
}

.pricing-section .pricing-btn:active {
  background: #F8EB5299;
  border: 1px solid #FFFFFF;
  color: #000000;
}
@media only screen and (min-width: 1200px) {
  .mynav .navbar-nav .nav-item .nav-link{
    padding: 10px 20px !important;
    max-height: 47px;
    border-radius: 50px;
    border: 1px solid #1E3C58;
    box-shadow: 0px 0px 12px 0px #459EFF33;
    background: var(--nav-bg);
  }

  .mynav .navbar-nav .nav-item .nav-link.green {
    background: #00f037;
  }

  .mynav .navbar-nav .nav-item .nav-link:hover {
    background-color: #F8EB52;
    border: 1px solid #FFFFFF;
    color: #000000 !important;
  }

  .mynav .navbar-nav .nav-item .nav-link:active {
    background: #F8EB5299;
    border: 1px solid #FFFFFF;
    color: #000000;
  }

  .mynav .navbar-nav .nav-item .nav-link:hover svg path,
  .mynav .navbar-nav .nav-item .nav-link:active svg path {
    stroke: #000;
  }
}
@media only screen and (max-width: 380px) {
  .price-items {
    flex-direction: column;
    gap: 5px;
  }

  .price-items a {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .mynav .navbar-brand {
    display: none;
  }

  .price-items {
    margin-top: 10px;
  }

  .mynav.loggedin .container {
    align-items: flex-start;
    position: relative;
    flex-direction: column;
    gap: 0px;
  }

  .mynav.loggedin .navbar-toggler {
    z-index: 1;
    padding-left: 0;
  }

  .mynav.loggedin .pricing-section {
    flex-direction: column-reverse;
    left: 0;
    width: 100%;
  }

  .mynav.loggedin .pricing-section .pricing-buttons {
    width: 100%;
    justify-content: flex-start;
    position: fixed;
    top: 19px;
    left: calc(100vw - 50px);
  }

  .pricing-section .pricing-btn {
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none;
    width: 34px;
    height: 34px;
    color: #728EAC !important;
  }

  .mynav.loggedin .pricing-section .price-items-wrap {
    width: 100%;
  }

  .mynav.loggedin .pricing-section .price-items-wrap .pricing-item {
    flex: 1;
  }

  .mynav.loggedin .header-collapse {
    top: 33px !important;
  }

  .navbar-toggle .icon-bar {
    width: 22px;
    transition: all 0.2s;
  }
  .navbar-toggle .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
  }
  .navbar-toggle .middle-bar {
      opacity: 0;
  }
  .navbar-toggle .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
  }
  .navbar-toggle.collapsed .top-bar {
      transform: rotate(0);
  }
  .navbar-toggle.collapsed .middle-bar {
      opacity: 1;
  }
  .navbar-toggle.collapsed .bottom-bar {
      transform: rotate(0);
  }
  .mynav .navbar-toggle {
      background: none !important;
      border: 0 !important;
      margin-top: 10px;
  }
  .mynav .icon-bar {
      background: #2196F3 !important;
  }

  .mynav .navbar-nav {
    align-items: center;
    text-align: center;
  }

  .navbar-nav .open .dropdown-menu {
    text-align: center;
  }

  .price-items a {
    flex: 1;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .mynav img.logo {
    height: 35px;
    margin-top: 10px;
  }
  .mynav .navbar-nav li a {
      padding: 10px 8px;
      font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .pricing-section {
    flex: 1;
  }
  .header-collapse {
    position: absolute;
    max-width: 330px;
    width: 100%;
    display: block !important;
    z-index: 100;
    top: calc(100% + 1px);
    left: 0;
    transition: all 0.3s;
    transform: translateX(-100%);
    background: var(--nav-bg);
    border-right: 1px solid #AFC6DB;
    border-bottom: 1px solid #AFC6DB;
    border-left: 1px solid #AFC6DB;
    border-radius: 0px 0px 12px 0px;
    overflow-x: hidden;
  }

  .dark .header-collapse {
      border-color: #1E3C58 !important;
  }

  .header-collapse .logo-mobile {
      display: block;
      width: 120px;
      height: auto !important;
      margin-top: 20px !important;
      margin: auto;
  }

  .header-collapse.open {
      transform: translateX(0);
  }

  .header-collapse .navbar-nav {
      width: 100%;
      padding: 20px 0;
      gap: 0;
  }

  .header-collapse .navbar-nav .nav-item {
      text-align: center;
      width: 100%;
      cursor: pointer;
      order: 3;
  }

  .header-collapse .navbar-nav .nav-item.login-item {
      order: 1;
  }

  .header-collapse .navbar-nav .nav-item a {
      padding: 15px 25px !important;
      justify-content: space-between;
  }

  .header-collapse .navbar-nav .nav-item .pricing-section {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
      max-width: inherit;
      font-size: 12px;
      justify-content: center;
      flex-direction: column;
  }

  .header-collapse .navbar-nav .nav-item .btn-login {
      margin-top: 20px;
  }

  .header-collapse .navbar-nav .nav-item .nav-link:hover {
      background-color: var(--select);
  }

  .header-collapse .navbar-nav .nav-item .dropdown-menu {
      text-align: center;
      border: 0;
      margin: 0;
      padding: 0;
      background-color: transparent;
      padding: 10px;
  }

  .header-collapse .navbar-nav .nav-item .dropdown-menu .dropdown-item {
      padding: 10px !important;
      color: var(--nav-text) !important;
      font-size: 14px;
      border-bottom: 0;
      justify-content: flex-start;
  }

  .navbar .navbar-nav .nav-item .nav-link svg {
      display: block !important;
  }
}

@media (max-width: 1420px) {
  .navbar .navbar-nav .nav-item .nav-link svg {
      display: none;
  }
}
