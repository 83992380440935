.custom-checkbox label {
  display: block;
  position: relative;
  padding-right: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--text);
}

.custom-checkbox.disabled label {
  color: #ababab;
}

.custom-checkbox.left label {
  padding-right: 0;
  padding-left: 35px;
}

.custom-checkbox:last-child label {
  margin-bottom: 0;
}

.custom-checkbox label input {
  position: absolute;
  /* opacity: 0; */
  cursor: pointer;
  height: 0;
  width: 0;
  right: 0;
}

.custom-checkbox.left label input {
  left: 0;
  right: initial;
}

.custom-checkbox label input:before {
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  width: 24px;
  border: 2px solid rgb(76, 80, 92);
  border-radius: 5px;
  content: '';
}

.custom-checkbox.disabled label input:before {
  border-color: #ababab;
}

.custom-checkbox.left label input:before {
  left: 0;
  right: initial;
}

.custom-checkbox label input:checked::before {
  appearance: none;
  border: 2px solid #449EFF;
  background-color: #449EFF;
  transition: box-shadow 0.2s ease 0s;
  outline: none;
}

.custom-checkbox label input:checked::after {
  position: absolute;
  content: '';
  left: -15px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.custom-checkbox.left label input:checked::after {
  right: -15px;
  left: initial;
}
