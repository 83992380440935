.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.per-page-count-selector {
    margin-bottom: 0px !important;
    margin-left: 30px;
    display: flex;
    align-items: center;
}

.per-page-count-selector label {
    margin-right: 15px;
    margin-bottom: 0px !important;
    font-size: 16px;
}

.pagination {
    border-bottom: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.pagination li {
    margin-left: 5px !important;
    margin-right: 5px !important;
    width: 35px;
    height: 35px;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #dee2e6;
}

.pagination li.selected {
    border-color: #76bde7;
    background: #99ceed;
}

.pagination li a {
    padding-bottom: 0px !important;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px !important;
}