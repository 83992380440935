.notification-modal {
  position: fixed;
  background-color: var(--modal);
  padding: 24px;
  top: 0;
  margin: auto;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 1px 6px rgba(5,27,44,.06),0 2px 32px rgba(5,27,44,.16);
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  font-size: 16px;
  color: var(--text);
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  transition: transform 0.3s ease-in;
  z-index: 100;
}

.notification-modal.open {
  transform: translateX(-50%) translateY(0);
}

.notification-modal img {
  width: 80px;
  object-fit: contain;
}

.notification-modal .notification-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.notification-modal .notification-actions .btn {
  padding: 12px 24px;
  color: var(--text-blue);
}

.notification-modal .notification-actions .btn-subscribe {
  color: white;
  background-color: #449EFF;
}
