:root {
  --table: rgba(255, 255, 255, 0.7);
  --table-striped: rgba(0, 0, 0, 0.05);
  --table-border: #dee2e6;
  --sub-table: #fff6f6;
  --sub-table-header: rgb(253 227 227);
  --box: rgba(32, 95, 188, 0.66);
  --box-line: #ffd600;
  --text: black;
  --text-light: #2b2b2b;
  --text-blue: #2b8df5;
  --nav-bg: #dbedff;
  --nav-sub-bg: #728eac;
  --nav-text: #182f44;
  --background: transparent;
  --modal: #fff;
  --modal-title: #363636;
  --label: #666666;
  --input: white;
  --loading: #f5f5f5;
  --select: #e6e6e6;
  --hover-blue: #1083ff;
  --hover-danger: #dd5859;
  --hover-yellow: #ffe500;
  --hover-green: #2ecc71;
  --home-bg: rgba(255, 255, 255, 0.5);
  --class-bg: #2b2b2b;
  --tournament: #e9ccff;
  --table-row-odd: #d8e6ee;
  --table-row-even: #e5f2fb;
  --tag: #f2f2f2;
  --marketplace-bg: #bcdff3;
  --fused-chicken-bg: #e8ffe8;
  --material-chicken-bg: #ffeff2;
  --box-shadow: rgba(0, 0, 0, 0.5);

  /* coin-mint     */
  --coin-mint-border: 1px solid rgba(0, 0, 0, 0.1);
  --coin-mint-top-bg: #fff;
  --coin-mint-progress-border: 2px solid #628500;
  --coin-mint-progress-bottom-color: #6e9500;
  --coin-mint-switch-active: #599ef8;
  --coin-mint-bottom-bg: #eef5fe;
  --line-gradient-1: #daebf6;
  --line-gradient-2: #fdfeff;

  /* track ownership     */
  --track-ownership-bg: #e3f1ff;
  --table-btn: #c0e1ff;
  --track-ownership-table-footer-border-color: #1283ff;
  --track-ownership-icon-bg: #d6eaff;
  --track-ownership-mobile-header: #459eff;

  /* race tracks */
  --race-track-table-row-even-bg: #d6eaff 70%;
  --race-track-table-row-odd-bg: red;
  /* presale-dashboard */
  --presale-dashboard-copy-icon: #000000;
  /* race-details */
  --race-details-text: #003977;
    /* chicken-profile */
    --chicken-profile-bargraph-bg: #D3E9FF;
    --chicken-card-comp-border: #A0CEFF;
    --checkout-modal-bg: #D6EAFF;
    --complete-checkout-border: #1283FF;
    --daily-pass-text-color: #FFFFFF;
    --packing-order-text-color: #527DAA;
    --packing-button-bg: #C1DFFF;
    --daily-pass-bg: #DBEDFF;
    --races-tab-bg: #DBEDFF;




  /* jewels */
  --jewel-input-bg: #D6EAFF;
  --jewel-bg: #E3F1FF;
  --jewel-divider-bg: #A0CEFF;


  /**
     * chicken-staking
     */
  --chicken-staking-title: #1283ff;
  --chicken-staking-switch-active: #1283ff;
  --chicken-staking-divider: #1283ff;
  --chicken-staking-bg-secondary: #d6eaff;

  /**
        * New
        */
  --bg-primary: #e3f1ff;
  --race-bg: #dbedff;
  --race-bg-hover: #dbedff;
  --race-bg-dark: #d3e9ff;
  --primary: #1283ff;
  --race-text: #003977;
  --race-text-gray: #728eac;
  --race-text-light: #003977;
  --race-line: #a0ceff;
  --race-track-img-gradient: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      #162431 119.71%
    ),
    linear-gradient(90deg, rgba(22, 36, 49, 0.5) 0%, rgba(0, 0, 0, 0) 175.27%);
  --race-modal-bg: #2d455f;
  --mini-scroll: #9AB2CA;

  --waitlist-card-bg: #C0E1FF;
  --waitlist-card-box-shadow: #A0CEFF33;
  --community-sale-card-shadow: #1283FF4D;
}

@import url(./checkbox.css);

@font-face {
  font-family: "Montserrat-Regular";
  src: url(fontfamily/Montserrat-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url(fontfamily/Montserrat-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url(fontfamily/Montserrat-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url(fontfamily/Montserrat-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: "DiloWorld";
  src: url(fontfamily/DiloWorld.ttf);
  font-display: swap;
}

@font-face {
  font-family: "Chyletoon-Condensed";
  src: url(fontfamily/Chyletoon-Condensed.ttf);
  font-display: swap;
}

@font-face {
  font-family: "CoreSansDS45Medium";
  src: url(fontfamily/CoreSansDS45Medium.otf);
  font-display: swap;
}

@font-face {
  font-family: "QuestaSans-Regular";
  src: url(fontfamily/QuestaSans-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeue-Regular";
  src: url(fontfamily/HelveticaNeue-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url(fontfamily/Roboto-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(fontfamily/Roboto-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url(fontfamily/Roboto-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat-Regular";
  background: linear-gradient(var(--background), var(--background)),
    url("../images/banner2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  font-size: 14px;
}

#chicken-game {
  display: none;
}

.overlay-bg {
  background: linear-gradient(rgba(53, 162, 22, 0.14), rgba(53, 162, 22, 0.14)),
    url("../images/banner.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body {
  overflow: auto;
  width: 100%;
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

iframe#squid_widget {
  max-width: 440px;
  width: 100%;
  border: 0;
  margin: 20px 10px;
}

.table-section {
  flex: 1;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--table-striped);
}

/* Scrollbar Styling */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: var(--nav-bg);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

.modal-content {
  background: var(--modal);
}

.modal-content .modal-header {
  color: var(--text);
}

.modal-content .modal-body h3,
.modal-content .modal-body p {
  color: var(--text);
}

.mint-serum {
  padding-top: 50px;
  padding-bottom: 60px;
  text-align: center;
  flex: 1;
  background: var(--table);
}

.mint-serum .mint-serum-warp {
  display: flex;
  justify-content: center;
  max-width: 1330px;
  margin: 0px auto;
}

.mint-serum .serum-container {
  background: var(--modal);
  padding: 20px 30px;
  border-radius: 10px;
  margin: 10px;
  color: var(--text);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mint-serum .serum-video,
.video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.mint-serum .serum-video iframe,
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.mint-serum h1 {
  position: relative;
  font-family: "DiloWorld";
  color: #449eff;
  font-size: 40px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.mint-serum h4 {
  position: relative;
  font-family: "DiloWorld";
  color: #e67d0a;
  margin-top: px;
  font-size: 30px;
  letter-spacing: 1px;
}

.mint-serum h5 {
  font-size: 24px;
  margin: 0;
}

.mint-serum .btn {
  background: #449eff;
  border: 1px solid #449eff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  transition: 300ms ease-out !important;
  text-decoration: none;
}

.mint-serum .btn:hover {
  background-color: transparent;
  color: #449eff;
}

.mint-serum .btn-minus,
.mint-serum .btn-plus {
  font-size: 23px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mint-serum .amount {
  width: 70px;
  text-align: center;
}

.mint-serum .btn-mint {
  height: 54px;
  width: 275px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 28px;
  cursor: pointer;
}

.mint-serum .btn-mint.disabled {
  background-color: #a4a4a4;
  border-color: #a4a4a4;
}

.mint-serum p {
  margin: 0;
}

.mint-serum b {
  font-weight: bold;
  font-family: "Montserrat-bold";
}

.mint-serum a {
  text-decoration: underline;
}

.mint-serum ul {
  list-style-position: inside;
  padding-left: 0;
}

.mint-serum ul li {
  margin: 5px 0;
}

.mint-serum .serum-mint-stage {
  width: 100%;
  min-width: 360px;
  margin-top: 10px;
}
.mint-serum .serum-mint-stage .serum-mint-stage-container {
  position: relative;
  background: #449eff;
  width: 100%;
  display: flex;
  padding: 10px 20px;
  border-radius: 5px;
  justify-content: space-between;
  color: white;
  overflow: hidden;
  /* font-size: 16px; */
}

.mint-serum .serum-mint-stage .solid {
  color: #2ecc71;
}

.mint-serum .serum-mint-stage .remaining {
  color: rgb(255, 138, 138);
}

.mint-serum
  .serum-mint-stage
  .serum-mint-stage-container
  .serum-mint-stage-progress {
  position: absolute;
  height: 4px;
  background-color: #2ecc71;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.mint-serum .serum-mint-stage .serum-mint-stage-container::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: rgb(255, 96, 96);
  bottom: 0;
  left: 0;
}

.mint-serum .serum-mint-stage .stage-price {
  font-size: 12px;
  justify-content: space-around;
  margin-top: 5px;
}

.get-chicken {
  background: var(--table);
  padding-top: 50px;
  padding-bottom: 60px;
}
.get-chicken .chicken-img {
  border-radius: 20px;
  max-width: 100%;
}
.get-chicken .home-list h1 {
  position: relative;
  font-family: "Chyletoon-Condensed";
  color: var(--text-blue);
  margin-top: 35px;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
.get-chicken .home-list p a {
  color: var(--text-blue);
}
.get-chicken .home-list p a.link {
  color: #007bff;
}
.get-chicken .home-list p {
  line-height: 28px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 25px;
  color: var(--text);
}

.race-chicken {
  padding-top: 50px;
  padding-bottom: 60px;
  /* background: var(--home-bg); */
}
.race-chicken .chicken-img {
  border-radius: 20px;
  max-width: 100%;
}

.race-chicken .home-list video {
  width: 100%;
  border-radius: 20px;
}

.race-chicken .home-list h1 {
  position: relative;
  font-family: "Chyletoon-Condensed";
  color: var(--text-blue);
  margin-top: 35px;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 20px;
}
.race-chicken .home-list h4 {
  position: relative;
  font-family: "DiloWorld";
  color: #e67d0a;
  margin-top: px;
  font-size: 30px;
  letter-spacing: 1px;
  margin-bottom: 0px;
}
.race-chicken .home-list .mt-heading {
  margin-top: 15px;
}

.race-chicken .home-list p a {
  color: #449eff;
}
.race-chicken .home-list p {
  line-height: 28px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 25px;
  padding-right: 50px;
  color: var(--text);
}

.race-chicken .btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 10px;
  background: #449eff;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  width: fit-content;
}

.get-chicken .home-list h4 {
  position: relative;
  font-family: "DiloWorld";
  color: #e67d0a;
  margin-top: px;
  font-size: 30px;
  letter-spacing: 1px;
  margin-bottom: 0px;
}
.get-chicken .home-list .mt-heading {
  margin-top: 15px;
}

.build-right {
  padding-top: 50px;
  padding-bottom: 50px;
  /* background: var(--home-bg); */
}
.build-right h1 {
  position: relative;
  font-family: "Chyletoon-Condensed";
  color: var(--text-blue);
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 40px;
}
.build-right p {
  color: var(--text);
  font-family: "Roboto";
  font-weight: 300;
  font-size: 20px;
  text-align: center;
}
.build-box {
  background: var(--modal);
  border-radius: 15px;
  padding: 15px 25px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}
.build-box img {
  margin: auto;
  text-align: center;
  height: auto;
}
.build-box .d-flex {
  align-items: center;
}

.build-box h4 {
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 300;
  color: #1083ff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.build-box p {
  font-size: 20px;
  margin-bottom: 0;
  color: var(--text);
}

.home .market-card {
  background: var(--modal);
  border-radius: 15px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
}

.home .market-card .market-card-title {
  position: relative;
  font-family: "Chyletoon-Condensed";
  color: var(--text-blue);
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 20px 20px 0;
}

.home .market-card .market-card-content {
  padding: 10px 20px 20px;
}

.home .market-card .market-card-content p {
  text-align: left;
}

.home .market-card .market-card-content h5 {
  position: relative;
  font-family: "Chyletoon-Condensed";
  color: var(--text-blue);
  font-size: 36px;
  font-weight: bold;
}

.home .market-card .market-card-content .wagering-handle {
  margin: 10px 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}

.home .market-card .market-card-content .wagering-handle p {
  margin-bottom: 0;
}

.home .market-card .market-card-content .wagering-handle .img-container {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.discord-bottom {
  position: relative;
}

.discord-bottom a {
  position: absolute;
  right: 0;
  top: -100px;
}

.discord-bottom a img {
  height: 90px;
}

.filter-btns .buttons .btn {
  border-radius: 50px;
  min-height: 44px;
  border: 1px solid #449eff;
  color: var(--text);
  padding: 10px 25px;
  background: var(--table);
  padding-right: 45px;
  position: relative;
  margin-right: 10px;
  float: left;
}

.filter-btns .buttons .btn .selected-text {
  font-weight: bold;
}

.clear-all {
  color: #dd5859;
  display: inline-block;
  cursor: pointer;
}

.filter-btns .buttons .btn i {
  position: absolute;
  right: 7px;
  background: var(--select);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  padding-top: 5px;
  font-size: 11px;
  margin-left: 10px;
}

.table-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.table-box {
  background: var(--table);
  padding: 10px 0px;
  border-radius: 6px;
}

.table-box ul {
  list-style-type: none;
  padding-top: 10px;
  padding-left: 0;
  margin: 0;
  padding-bottom: 13px;
  width: 100%;
}

.table-box ul li {
  display: inline;
  margin-right: 30px;
  margin-left: 30px;
  cursor: pointer;
}

.table-box ul li a {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: var(--text) !important;
  text-decoration: none;
  padding-bottom: 14px;
}

.table-box ul .active a {
  font-weight: 600;
  border-bottom: 4px solid var(--text);
}

.table-box .navbar {
  border-bottom: 2px solid #575757;
  padding-bottom: 0px;
}

.table-box .navbar-toggler {
  padding: 0 7px;
  border: 0;
  margin-bottom: 10px;
}

.table-box .navbar-toggler .navbar-toggler-icon {
  width: 25px;
  height: 25px;
}

.tournament .tournament-title-collapse {
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tournament .tournament-title-collapse .tournament-title {
  padding-right: 30px;
}

.tournament .tournament-title-collapse::after {
  display: inline-block;
  margin-left: 20px;
  vertical-align: 20px;
  content: "";
  border-top: 10px solid var(--text);
  border-right: 10px solid transparent;
  border-bottom: 0;
  border-left: 10px solid transparent;
  position: absolute;
  right: 0;
  transition: all 0.5s;
  top: 20px;
}

.tournament .tournament-title-collapse[aria-expanded="true"]::after {
  transform: rotate(180deg);
}

.tournament .tournament-title {
  color: var(--text);
  padding: 10px 20px;
  text-align: center;
  font-size: 24px;
}

.tournament .tournament-title-collapse.new {
  display: block;
  text-align: center;
}

.tournament .tournament-title-collapse.new::after {
  display: none;
}

.tournament .tournament-title-collapse.new .tournament-title {
  margin-bottom: 0;
}

.tournament .tournament-title-collapse.new .tournament-rule-toggle {
  color: #1083ff;
  font-weight: 700;
}

.tournament .tournament-description {
  color: var(--text);
  padding: 10px 20px;
  text-align: center;
  white-space: pre-line;
}

.tournament .tournament-description table {
  width: 100%;
  table-layout: fixed;
}

.tournament .tournament-description table th,
.tournament .tournament-description table td {
  border: 1px solid;
}

.tournament .progress-bar-container {
  width: 140px;
  padding: 5px;
  border-radius: 5px;
  background: #cfe2f4;
  height: 26px;
  margin: auto;
  position: relative;
}

.tournament .progress-bar-container::before {
  content: "";
  height: 100%;
  width: 2px;
  background-color: var(--text);
  position: absolute;
  bottom: 5px;
  left: 41.666666666%;
}

.tournament .progress-bar-container::after {
  content: "";
  height: 100%;
  width: 2px;
  background-color: var(--text);
  position: absolute;
  bottom: 5px;
  left: calc(75% - 2px);
}

.tournament .progress-bar-container .progress-bar {
  height: 100%;
  background: var(--nav-bg);
  border-radius: 3px;
  width: 100%;
  z-index: 100;
}

.tournament .progress-bar-container .progress-bar .progress-bar-slot {
  height: 100%;
}

.tournament .laurels-img {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}

.tournament .laurel-items-img {
  width: 100%;
  padding: 20px;
}

.tournament .table-box ul {
  display: flex;
  padding-bottom: 0;
  border-bottom: 2px solid #575757;
}

.tournament .table-box ul li {
  flex: 1;
  margin-bottom: -2px;
}

.tournament .table-box ul li a {
  color: var(--text);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tournament .table-box ul li a:hover {
  color: var(--text);
}

.tournament .table-box ul .active a {
  /* border-bottom: 2px solid var(--text); */
}

.tournament .table-box .table tr {
  position: relative;
}

.tournament .table-box .table tr.assigned td:first-child:before {
  content: "";
  height: 100%;
  width: 10px;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #1083ff 20%, rgb(223, 241, 249, 0) 100%);
  position: absolute;
}

.tournament .table-box .table tr td {
  padding: 10px 20px;
}

.tournament .loading-column {
  margin: 7px 0;
}

.table-box .table-buttons {
  padding: 20px 20px;
  margin-top: 10px;
}

.table-box .table-buttons .btn-races {
  background: #1083ff;
  border-radius: 5px;
  border-color: #1083ff;
  color: #fff;
  width: 110px;
  height: 40px;
  padding: 10px 8px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.table-box .table-buttons .btn-races:hover {
  background: var(--hover-blue);
  color: #fff;
}

.table-box .table-buttons .btn-filters {
  background: #dd5859;
  border-color: #dd5859;
  border-radius: 5px;
  color: #fff;
  width: 110px;
  height: 40px;
  padding: 10px 8px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.table-box .table-buttons .btn-filters span {
  margin-right: 5px;
}

.table-box .table-buttons .btn-filters:hover {
  background: var(--hover-danger);
  color: #fff;
}

.table-box .table tr {
  cursor: pointer;
}

.table-box .table tr th {
  background: var(--table);
  padding: 18px 20px;
  vertical-align: middle;
  text-align: center;
  color: var(--text);
}

.table-box .table tr td {
  padding: 18px 20px;
  vertical-align: middle;
  text-align: center;
  color: var(--text);
  border-top: 0px;
  min-height: 80px;
}

.table-box .reset-expansion-style {
  /* width: 80%;
    */
  /* max-width: 922px; */
  margin: auto;
  background: var(--sub-table);
  padding: 0px 0px;
}

.table-box .reset-expansion-style .pick-gate {
  padding-top: 20px;
  padding-bottom: 20px;
}

.table-box .reset-expansion-style .pick-gate h2 {
  font-family: "Montserrat-SemiBold";
  font-size: 24px;
  color: var(--text);
  margin-bottom: 50px;
}

.table-box .reset-expansion-style .pick-gate .open-gates {
  text-align: center;
}

.table-box .reset-expansion-style .pick-gate .open-gates span {
  font-size: 24px;
  font-family: "Montserrat-SemiBold";
  color: var(--text);
  margin-right: 30px;
}

.table-box .reset-expansion-style .pick-gate .open-gates .btn {
  background: #ffffff;
  border: 1px solid #1083ff;
  box-sizing: border-box;
  border-radius: 27.5px;
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  color: var(--text);
  padding: 6px 50px;
  margin: 5px;
}

.table-box .reset-expansion-style .pick-gate .open-gates .btn-races {
  background: #8f4ebf;
  border-radius: 5px;
  border-color: #8f4ebf;
  color: #fff;
  width: 150px;
  padding: 12px 14px;
  font-size: 18px;
  transition: 300ms ease-out !important;
}

.table thead th {
  border-bottom: 2px solid var(--table-border);
  border-top: 1px solid var(--table-border);
}

.react-bootstrap-table table {
  table-layout: auto !important;
  font-size: 14px;
}

.react-bootstrap-table table .reset-expansion-style {
  padding: 0 !important;
}

.react-bootstrap-table table .reset-expansion-style .row-expansion-style {
  padding: 0 !important;
}

.react-bootstrap-table table .reset-expansion-style .row-expansion-style table {
  font-size: 13px;
}

/* .table-box .reset-expansion-style .pick-gate .open-gates:hover{
   background: #fff;
   color: #77BEE8;
} */

.price-green {
  color: #27b18a;
  font-weight: bold;
  white-space: nowrap;
}

.closes-time {
  white-space: nowrap;
}

.table-box .table {
  margin-bottom: 0 !important;
}

.table-box .top-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 35px;
  padding-top: 10px;
}

.table-box .top-heading h4 {
  font-weight: 500;
  font-size: 20px;
  color: var(--text);
}

.table-box .btn-enter {
  background: #ffe500;
  border-radius: 5px;
  border-color: #ffe500;
  color: var(--text);
  padding: 8px 24px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.disable-watchBtn {
  background: #8d8b7e;
  border-radius: 5px;
  border-color: #ffac0b;
  color: var(--text);
  padding: 8px 24px;
  font-size: 14px;
  transition: 300ms ease-out !important;
  pointer-events: none;
  cursor: none;
}

.table-box .btn-enter:hover {
  background: var(--hover-yellow) !important;
  color: #fff !important;
}

.table-box .reset-expansion-style .table tr th {
  background: var(--sub-table-header) !important;
}

.table-box .reset-expansion-style .table tr td {
  background: var(--sub-table) !important;
  padding: 12px 20px;
  border: 0 !important;
}

.table-box .reset-expansion-style .table tr td a {
  text-decoration: underline;
}

.text-class {
  background: #2b2b2b;
  border-radius: 3px;
  padding: 6px 14px;
  width: 77px;
  height: 24px;
  font-weight: 500;
  font-size: 13px;
  color: #ffffff;
}

.text-class.light {
  background: var(--class-bg);
}

.chick-icon {
  width: auto !important;
  height: 35px !important;
}

.chick-icon-small {
  width: auto !important;
  height: 30px !important;
}

/* .icon-terrain img{
   display: block;
   margin: auto;
   transition: 300ms ease-out !important;
}
.terrain-text{
   visibility: hidden;
}
.icon-terrain:hover .terrain-text{
   visibility:visible;
} */

/**** filter-popup ********/

.filter-popup.chicken-filter-popup.modal-dialog {
  z-index: 9999;
}

.filter-popup.modal-dialog {
  max-width: 500px;
  margin: 30px auto;
}

.filter-popup.modal-dialog .modal-header {
  padding-bottom: 5px;
}

.filter-popup.modal-dialog .modal-header h3 {
  color: #dd5859;
}

.modal-dialog .footer-btn {
  border: 0;
  padding-top: 0;
  padding-bottom: 20px;
  text-align: center;
}

.filter-popup.modal-dialog label {
  font-weight: 400;
  font-size: 14px;
  color: var(--label);
}

.filter-popup.modal-dialog .modal-body {
  padding-bottom: 10px;
}

.modal-dialog .footer-btn .btn {
  width: 47%;
  padding: 10px 20px;
}

.modal-dialog .footer-btn .btn-reset {
  border: 1px solid #dd5859;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  background: #dd5859;
  color: #fff;
  transition: 300ms ease-out !important;
  margin-right: 10px;
}

.modal-dialog .footer-btn .btn-filter-modal {
  background: #1083ff;
  border-color: #1083ff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  transition: 300ms ease-out !important;
}

.modal-dialog .footer-btn .btn-reset:hover {
  background: var(--hover-danger) !important;
  color: #fff !important;
}

.modal-dialog .footer-btn .btn-filter-modal:hover {
  background: var(--hover-blue) !important;
  color: #fff !important;
}

.my-chickens-modal.modal-dialog .modal-body {
  overflow: auto;
  height: 80vh;
  color: var(--text);
}

/**** filter-popup ********/

.bridge-modal .modal-content {
  background: radial-gradient(
    500px,
    rgb(159, 150, 165) 0px,
    rgb(48, 48, 89),
    rgb(13, 4, 21)
  );
}

.bridge-modal .modal-content .modal-header {
  background-color: hsl(0deg 0% 0% / 60%);
}

.bridge-modal .modal-content .modal-header h3 {
  color: #fff;
}
.bridge-modal .modal-content .modal-body {
  padding: 0;
}

.bridge-modal .modal-content .modal-body iframe {
  border: 0;
  height: 500px;
}

/**** select-chicken *****/

/* .select-chicken.modal-dialog {
    width: 900px;
    max-width: 900px;
    margin: 30px auto;
} */

.select-chicken.modal-dialog .modal-header {
  border: 0;
  text-align: center;
  padding-bottom: 0px;
  padding-top: 10px;
}

.select-chicken.modal-dialog .modal-header .modal-title {
  color: var(--modal-title);
  font-size: 18px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 5px;
}

.select-chicken.modal-dialog h4 {
  font-weight: 500;
  font-size: 17px;
  color: var(--text-light);
  font-family: "Montserrat-Medium";
}

.select-chicken .select-chicken-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-chicken .order-table .img-pic {
  width: 45px;
}

.select-chicken .select-chicken-row .btn-filters {
  background: #1083ff;
  border-color: #1083ff;
  border-radius: 5px;
  color: #fff;
  width: 110px;
  height: 40px;
  padding: 10px 8px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.select-chicken .select-chicken-row .btn-filters span {
  margin-right: 5px;
}

.select-chicken .select-chicken-row .btn-filters:hover {
  background: var(--hover-blue);
  color: #fff;
}

.accordion-toggle.change-bg td {
  background: #fcdbdb !important;
}

.select-chicken .modal-content {
  padding: 10px 15px;
}

.select-chicken .form-control-feedback {
  position: absolute !important;
  color: #449eff;
  left: 15px;
  top: 13px !important;
  font-size: 17px;
}

.select-chicken .customInput {
  border-radius: 50px;
  height: 44px;
  max-width: 400px;
  padding-left: 50px;
  border: 1px solid #449eff;
  background-color: var(--input);
  color: var(--text);
}

.select-chicken .has-feedback {
  max-width: 400px;
  margin: auto;
  position: relative;
}

.select-chicken .order-table {
  border-radius: 7px;
  padding: 5px 10px;
  margin-top: 25px;
  color: var(--text);
}

.select-chicken .order-table tr th {
  color: #fff;
  vertical-align: middle;
  background: #449eff;
  border-top: 1px solid var(--table-border);
}

.select-chicken .order-table tr td {
  vertical-align: middle;
  border-top: 1px solid var(--table-border);
}

.select-chicken .order-table .btnEnter {
  background: linear-gradient(0deg, #0f4681, #0f4681);
  border-radius: 5px;
  color: #fff;
  padding: 6px 17px;
  font-size: 12px;
  text-decoration: none;
}

.select-chicken .race-info {
  display: flex;
  align-items: center;
  justify-items: center;
}

.select-chicken .race-info p {
  flex: 1;
  margin: 0;
  font-size: 14px;
}

.select-chicken .race-info img {
  width: 50px;
  height: auto;
  margin: 0 10px;
}

/**** select-chicken ********/

.btn-close {
  background: #449eff;
  border-radius: 7px;
  padding: 10px 25px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 17px;
  color: #fff;
  line-height: 21px;
  width: 144px;
  height: 39px;
}

/**** choose-an-account ****/
.choose-an-account {
  border-radius: 10px;
  width: 450px;
}

.choose-an-account .modal-header {
  border-bottom: 0;
}

.choose-an-account .modal-body {
  padding: 10px 30px 30px;
}

.choose-an-account h3 {
  width: 100%;
  font-weight: 700;
  font-size: 28px;
  line-height: 22px;
  font-family: "Roboto";
  /* identical to box height */
  text-align: center;
  padding-top: 20px;
  color: var(--modal-title);
}

.choose-an-account p {
  font-size: 18px;
  line-height: 22px;
  font-family: "Roboto";
  font-weight: 300;
  /* identical to box height */
  text-align: center;
  color: var(--modal-title);
  opacity: 0.5;
}

.choose-an-account .choose-account-container {
  margin-top: 30px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.choose-an-account .choose-account-container .choose-account-item {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  cursor: pointer;
  background-color: rgba(118, 190, 232, 0.1);
  position: relative;
  height: 60px;
  position: relative;
}

.choose-an-account .choose-account-container .choose-account-item:hover {
  background-color: rgba(118, 190, 232, 0.2);
}

.choose-an-account .choose-account-container .choose-account-item img {
  width: 32px;
  position: absolute;
}

.choose-an-account .choose-account-container .choose-account-item p {
  opacity: 1;
  margin-bottom: 0;
  font-family: "Roboto";
  font-weight: 700px;
  text-align: center;
  flex: 1;
}

/**** choose-an-account ****/

.terms-confirm-modal .modal-header {
  font-size: 20px;
  text-align: center;
  color: var(--text);
  text-align: center;
  justify-content: center;
  border-bottom: 0px;
}

.terms-confirm-modal .modal-body {
  color: var(--text);
}

.terms-confirm-modal .modal-body img {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  display: block;
}

.terms-confirm-modal .modal-body a {
  text-decoration: underline;
}

.terms-confirm-modal .modal-body p {
  font-size: 15px;
}

.terms-confirm-modal .modal-body .btn {
  display: block;
  margin: auto;
  margin-top: 20px !important;
}

/**** transaction-confirmation ***/

.transaction-confirmation.modal-dialog {
  width: 480px;
}

.transaction-confirmation.modal-dialog .modal-header {
  border: 0;
  text-align: center;
  padding-top: 25px;
}

.transaction-confirmation.modal-dialog .modal-content {
  padding: 0 15px;
}

.transaction-confirmation.modal-dialog .modal-header .modal-title {
  color: var(--modal-title);
  font-size: 18px;
  font-family: "Montserrat-SemiBold";
}

.transaction-confirmation.modal-dialog .modal-header .close {
  opacity: 1;
}

.transaction-confirmation.modal-dialog h4 {
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  font-family: "Montserrat-Bold";
  color: var(--modal-title);
  margin-bottom: 10px;
}

.transaction-confirmation.modal-dialog p {
  margin-bottom: 35px;
  font-family: "Montserrat-Medium";
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: var(--modal-title);
}

.bottom-btn {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 20px;
}

.bottom-btn .btn-go {
  border: 1px solid #1083ff;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: bold;
  background: #1083ff;
  font-size: 12px;
  text-align: center;
  color: #fff;
  padding: 10px 30px;
  width: 151px;
  margin-right: 10px;
  transition: 300ms ease-out !important;
}

.bottom-btn .btn-go:hover {
  background: var(--hover-blue);
  color: #fff;
}

.bottom-btn .btn-confirm {
  border: 1px solid #449eff;
  box-sizing: border-box;
  background: #449eff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #fff;
  padding: 10px 30px;
  width: 151px;
  transition: 300ms ease-out !important;
}

.bottom-btn .btn-confirm:hover {
  background: var(--hover-blue);
  color: #fff;
}

.complete-transaction {
  text-align: center;
  white-space: pre;
}

.complete-transaction.modal-dialog h3 {
  color: var(--modal-title);
  font-size: 18px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 20px;
  padding-top: 25px;
}

.complete-transaction.modal-dialog p {
  margin-bottom: 20px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: var(--modal-title);
  font-family: "Montserrat-Medium";
}

.complete-transaction.modal-dialog .loader-img {
  height: 120px;
}

.complete-transaction.modal-dialog h5 {
  font-family: "Montserrat-SemiBold";
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: var(--modal-title);
  white-space: initial;
}

.complete-transaction.modal-dialog h5.warning {
  color: red;
  margin-bottom: 25px;
}

.modal-open {
  padding-right: 0 !important;
}

/**** My Chickens *****/

.my-chickens {
  padding-top: 50px;
  padding-bottom: 50px;
  flex: 1;
}

.my-chickens .container h1,
.my-chickens .container h4 {
  color: var(--text);
}

.my-chickens .container .coop-box .count-badge {
  background: var(--modal);
  color: var(--text);
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 20px;
  border-radius: 5px;
  padding: 3px 5px;
}

.my-chickens .container .coop-box .availability {
  background: rgba(68, 158, 255, 0.12);
  border-radius: 17px;
  color: #449eff;
  font-size: 12px;
  padding: 2px 5px;
  white-space: nowrap;
  height: fit-content;
}

.my-chickens .container .coop-box .clothing-info {
  color: var(--text);
  font-size: 15px;
}

.my-chickens .filter-btn-wrap {
  display: flex;
  justify-content: flex-end;
}

.my-chickens-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 30px;
}

.my-chickens-top h1 {
  margin-top: 0;
  font-weight: 600;
  font-size: 28px;
  color: #ffffff;
}

.my-chickens-top h4 {
  font-size: 20px;
  color: #ffffff;
}

.my-chickens-top .btn-filters {
  background: #dd5859;
  border-color: #dd5859;
  border-radius: 5px;
  color: #fff;
  width: 110px;
  height: 40px;
  padding: 10px 8px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.my-chickens-top .btn-filters span {
  margin-right: 5px;
}

.btn-filters:hover {
  background: var(--hover-danger);
  color: #fff;
}

.filters-btns {
  display: flex;
}

.filter-actions {
  justify-content: flex-end;
}

.btn-refresh {
  background: #1083ff;
  border-color: #1083ff;
  border-radius: 5px;
  color: #fff;
  width: 110px;
  height: 40px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.btn-refresh:hover {
  background: var(--hover-blue);
  color: #fff;
}

.coop-box {
  background: var(--modal);
  border: 3px solid #1083ff;
  border-radius: 20px;
  padding: 10px 10px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.coop-box img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.coop-box h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: var(--text);
  margin-top: 10px;
  margin-bottom: 15px;
}

.coop-box ul {
  flex: 1;
  list-style-type: none;
  padding-left: 0;
  padding-bottom: 0px;
  text-align: center;
  margin-bottom: 0;
}

.coop-box ul li {
  display: inline;
  margin: 0 3px;
}

.coop-box ul li {
  border: 1px solid #1083ff;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 12px;
  /* identical to box height */
  cursor: pointer;
  color: #1083ff;
  padding: 8px 20px;
  display: inline-block;
  margin-bottom: 10px;
  font-family: "Montserrat-Medium";
  text-decoration: none;
}

.coop-box ul li:hover {
  background: var(--hover-blue);
  color: #fff;
}

.coop-box .btn-showdetails {
  background: #2ecc71;
  /* box-shadow: 1px 1px 0px 2px rgba(0, 0, 0, 0.91); */
  border-radius: 8px;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
}

.coop-box .btn-showdetails:hover {
  background: var(--hover-green);
  color: #fff;
}

.coop-box .chicken-loading {
  position: absolute;
  width: calc(100% - 20px);
  height: auto;
  top: 10px;
  left: 10px;
}

.coop-box .price-wrap {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  font-size: 13px;
  color: var(--text);
  margin-bottom: 10px;
}

.coop-box .price-wrap .price {
  display: flex;
  align-items: center;
}

.coop-box .price-wrap .price span {
  margin-top: 2px;
  font-size: 15px;
}

.coop-box .price-wrap .price.last span {
  font-size: 13px;
}

.coop-box .price-wrap .price img {
  width: 22px;
  height: 22px;
}

.coop-box .price-wrap .price img.offers-receives-icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.coop-box .price-wrap .price.last img {
  width: 15px;
  height: 15px;
}

.my-chickens-filter .modal-body {
  padding: 0px 25px;
  padding-bottom: 25px;
}

.my-chickens-filter .modal-title {
  font-family: "Montserrat-Medium";
  font-size: 24px;
  color: #dd5859;
}

.my-chickens-filter h4 {
  color: var(--text);
  font-weight: bold;
  padding-top: 10px;
}

.my-chickens-filter .modal-footer {
  border: 0;
  padding-bottom: 20px;
  text-align: left;
  padding: 15px;
}

.my-chickens-filter .modal-footer .btn-reset {
  border: 1px solid #dd5859;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  background: #dd5859;
  color: #fff;
  transition: 300ms ease-out !important;
  padding: 8px 30px;
  width: 164px;
  height: 40px;
}

.my-chickens-filter .modal-footer .btn-reset:hover {
  background: var(--hover-danger);
  color: #fff;
}

.my-chickens-filter .modal-footer .btn-filter-modal {
  background: #1083ff;
  border-color: #1083ff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  transition: 300ms ease-out !important;
  padding: 8px 30px;
  width: 164px;
  height: 40px;
}

.my-chickens-filter .modal-footer .btn-filter-modal:hover {
  background: var(--hover-blue);
  color: #fff;
}

/**** My Chickens *****/

/**** My Coops *****/
.my-coops {
  padding: 20px 0;
  overflow: hidden;
  flex: 1;
}
.my-coops .container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
}

.my-coops .coop-boxes {
  /* column-gap: 10px; */
  /* flex-wrap: nowrap; */
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 10px;
}

.my-coops .chicken {
  cursor: pointer;
}

.my-coops .coop-boxes .coop-box.selected {
  background-color: #1083ff !important;
}

.my-coops .coop-boxes .coop-box {
  border-width: 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  cursor: pointer;
  border-style: dashed;
  border-color: var(--text-light);
  text-align: center;
}

.my-coops .coop-boxes .coop-box p {
  color: var(--text);
  margin: 5px 0;
}

.my-coops .chicken-boxes {
  background-color: var(--table);
  margin-top: 20px;
  border-radius: 10px;
  padding: 20px;
  overflow-x: hidden;
  text-align: center;
  color: var(--text);
  flex: 1;
}

.my-coops .chicken-boxes .chicken p {
  text-align: center;
  color: var(--text);
  margin: 10px 0 20px 0;
}

.my-coops .chicken-boxes .coop-title {
  font-size: 20px;
  text-align: center;
  color: var(--text);
  margin-bottom: 30px;
}

.my-coops .filter-btn-wrap {
  display: flex;
  justify-content: flex-end;
}

.my-coops .coop-dropdown {
  display: none;
}

.my-coops .coop-chicken-placeholder {
  position: relative;
}

.my-coops .coop-chicken-placeholder .chicken-loading {
  position: absolute;
}
/**** My Coops *****/

/****** chicken-detail *****/

.chicken-profile-wrapper .chicken-main-header {
  padding: 20px 0;
  align-items: center;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.chicken-profile-wrapper .chicken-main-header a {
  padding: 5px 20px;
  border-radius: 10px;
  color: white;
}

.chicken-profile-wrapper
  .chicken-main-header
  a.active {
  background: var(--modal);
  color: #1083ff;
}

.chicken-detail .chicken-detail-container .chicken-info .blacklist-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  margin: auto;
  width: 100%;
}

.chicken-detail .chicken-detail-container .chicken-info .blacklist-icon img {
  width: 40px;
  cursor: pointer;
}

.chicken-detail .chicken-detail-container .chicken-info .btn-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 10px;
  background: #449eff;
  border-radius: 8px;
  width: 100%;
  margin-top: 1rem;
  border: 0px;
}

.chicken-detail .chicken-detail-container .chicken-info .list-details {
  margin-top: 1rem;
  padding: 15px;
  border-radius: 10px;
  background: var(--table);
  position: relative;
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .copy-link {
  position: absolute;
  top: 10px;
  right: 10px;
}

.chicken-detail .chicken-detail-container .chicken-info .list-details h5 {
  font-size: 14px;
  color: var(--text);
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .list-price {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  font-size: 16px;
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .list-price
  img {
  width: 15px;
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .list-price
  p {
  margin: 0;
  color: var(--text);
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .list-price
  p
  span {
  color: var(--text-light);
  font-size: 14px;
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .btn-group {
  display: flex;
  gap: 6px;
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .btn-group
  button {
  border-radius: 10px;
  flex: 1;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 0;
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .btn-group
  .btn-buy {
  background: #469ff8;
  color: white;
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .btn-group
  .btn-offer {
  background: var(--modal);
  color: #469ff8;
}

.chicken-detail
  .chicken-detail-container
  .chicken-info
  .list-details
  .btn-group
  button
  img {
  width: 16px;
}

.chicken-detail .chicken-info {
  padding-top: 70px;
  position: relative;
}

.chicken-detail .chicken-info .chicken-box-sm {
  position: sticky;
  top: 20px;
}

.chicken-detail .chicken-box-lg {
  margin-top: 0;
  padding: 30px 10px;
}

.chicken-wearables {
  padding: 20px;
  margin: 10px auto;
  background: var(--races-tab-bg);
  border: 1px solid var(--chicken-card-comp-border);;
  box-sizing: border-box;
  border-radius: 10px;
}

.chicken-wearables .chicken-clothings {
  display: flex;
  gap: 7px;
}

.chicken-wearables .chicken-clothings .chicken-clothing-item {
  width: 40px;
  height: 40px;
  background: var(--nav-bg);
  border: 1px solid #449eff;
  border-radius: 5px;
  position: relative;
}

.chicken-wearables .chicken-clothings .chicken-clothing-item i {
  color: white;
  background: red;
  padding: 3px;
  border-radius: 20px;
  width: 16px;
  height: 16px;
  line-height: 10px;
  position: absolute;
  z-index: 100;
  right: -5px;
  top: -5px;
  font-size: 12px;
  cursor: pointer;
}

.chicken-wearables .clothings {
  row-gap: 10px;
}

.chicken-wearables .clothings .clothing-item {
  background: var(--nav-bg);
  border: 1px solid #449eff;
  border-radius: 6px;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.chicken-wearables .clothings .clothing-item.equipped {
  border-color: #ffd549;
}

.chicken-wearables .clothings .chicken-loading {
  position: absolute;
}

.chicken-wearables .clothings .clothing-item .clothing-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 100;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  margin: 3px;
  font-weight: 100;
}

.chicken-wearables .clothings .clothing-item .clothing-btn.clothing-check-btn {
  background: #449eff;
  filter: drop-shadow(0px 2px 4px rgba(115, 103, 240, 0.4));
}

.chicken-wearables .clothings .clothing-item .clothing-btn.clothing-add-btn {
  background: #27b18a;
  filter: drop-shadow(0px 2px 4px rgba(115, 103, 240, 0.4));
}

.chicken-wearables .clothings .clothing-item .clothing-info {
  padding: 8px;
}

.chicken-wearables .clothings .clothing-item .clothing-info .clothing-info-top {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.chicken-wearables
  .clothings
  .clothing-item
  .clothing-info
  .clothing-info-top
  .clothing-name {
  font-size: 12px;
  color: var(--text);
}

.chicken-wearables
  .clothings
  .clothing-item
  .clothing-info
  .clothing-info-top
  .clothing-count {
  background: rgba(68, 158, 255, 0.12);
  border-radius: 17px;
  color: #449eff;
  font-size: 12px;
  padding: 2px 5px;
  white-space: nowrap;
  height: fit-content;
}

.chicken-wearables .clothings .clothing-item .clothing-info .clothing-id {
  font-size: 12px;
  color: var(--text-light);
}

.chicken-wearables .clothings .clothing-item .clothing-info .clothing-set {
  font-size: 12px;
  color: var(--text);
}

.chicken-history {
  background: var(--races-tab-bg);
  border-radius: 10px;
  border: 1px solid #42576D;
}

.chicken-history .table tr {
  cursor: pointer;
}

.chicken-history .table tr th {
  padding: 18px 20px !important;
  vertical-align: middle;
  text-align: center;
  background: var(--table);
  color: var(--text);
}

.chicken-history .table tr:nth-of-type(odd) {
  background-color: var(--table-striped);
}

.chicken-history .table tr td {
  padding: 18px 20px;
  height: 80px;
  vertical-align: middle;
  text-align: center;
  color: var(--text);
  border-top: 0px;
}
.chicken-trades .table tr:nth-of-type(odd) td {
  background-color: var(--table-row-odd);
}

.chicken-trades .table tr:nth-of-type(even) td {
  background-color: var(--table-row-even);
}

.chicken-trades .table tr td.actions-column {
  position: sticky;
  width: 125px;
  right: 0;
}

.chicken-trades .table tr th {
  padding: 0 20px !important;
}

.chicken-trades .table tr td {
  padding: 7px 20px;
  height: 55px;
}

.chicken-trades .table tr td .price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chicken-trades .table tr td .price .chicken-image {
  margin: 0 10px;
}

.chicken-trades .actions {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
.chicken-trades .btn {
  border-radius: 5px;
  color: #fff;
  width: 110px;
  font-size: 14px;
  transition: 300ms ease-out !important;
  height: 38px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chicken-trades .btn-accept,
.chicken-trades .btn-edit {
  background: #1083ff;
  border-color: #1083ff;
}

.chicken-trades .btn-accept:hover,
.chicken-trades .btn-edit:hover {
  background: var(--hover-blue);
}

.chicken-trades .btn-decline {
  background: #dd5859 !important;
  border-color: #dd5859;
}

.chicken-trades {
  overflow: hidden;
}

.chicken-trades #trades-offers,
.chicken-trades #trades-activities {
  max-height: 300px;
  overflow-x: hidden;
}

.chicken-trades #collapse .react-bootstrap-table {
  height: 300px;
  max-height: 300px;
}

.chicken-trades .chicken-trades-collapse {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding: 15px 20px;
}

.chicken-trades .chicken-trades-collapse .chicken-trades-title {
  font-size: 18px;
  color: var(--text);
}

.chicken-trades .chicken-trades-collapse::after {
  display: inline-block;
  margin-left: 20px;
  vertical-align: 20px;
  content: "";
  border-top: 6px solid var(--text);
  border-right: 6px solid transparent;
  border-bottom: 0;
  border-left: 6px solid transparent;
  position: absolute;
  right: 20px;
  transition: all 0.5s;
  top: 25px;
}

.chicken-trades .chicken-trades-collapse[aria-expanded="true"]::after {
  transform: rotate(180deg);
}

.chicken-badges-container {
  display: flex;
  padding: 20px;
  gap: 10px;
  background-color: var(--table);
  max-height: 300px;
  overflow-x: hidden;
  flex-wrap: wrap;
}

.chicken-badges-container .chicken-badge {
  width: 60px;
  height: 60px;
}

.chicken-badges-container .chicken-badge img {
  cursor: pointer;
  width: 60px;
  height: 60px;
}

.chicken-badges-container p {
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  color: var(--text);
}

.chicken-box-sm {
  max-width: 250px;
  margin: auto;
}

.chicken-box-sm .btn-list {
  background: #1083ff;
  border-color: #1083ff;
  border-radius: 5px;
  color: #fff;
  width: 110px;
  height: 40px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.chicken-box-sm .btn-list:hover {
  background: var(--hover-blue);
  color: #fff;
}

.chicken-box-sm img {
  width: 100%;
  border-radius: 5px;
}

.chicken-user {
  background: #11202E;
  padding: 8px 30px;
  max-width: 300px;
  margin: 0px auto;
  border-radius: 6px;
}

.chicken-user .table {
  text-align: center;
  margin-bottom: 0;
}

.chicken-user .table tr th {
  text-align: center;
  color: #fff;
  font-family: "Montserrat-SemiBold";
  border: 0 !important;
  padding: 0;
}

.chicken-user .table tr td {
  color: #fff;
  border: 0 !important;
  padding: 0;
  padding-top: 10px;
  width: 50%;
}

.chicken-user .table tr td a {
  cursor: pointer;
  text-decoration: underline;
  color: white;
}

.chicken-box-lg {
  padding: 30px 15px;
  padding-bottom: 0;
  margin: 10px auto;
  background: var(--box);
  border: 2px solid var(--box-line);
  box-sizing: border-box;
  border-radius: 10px;
}

.chicken-box-lg .table {
  text-align: center;
  margin-bottom: 0;
}

.chicken-box-lg .table tr th {
  text-align: center;
  color: #fff;
  font-family: "Montserrat-SemiBold";
  border: 0 !important;
  font-size: 16px;
  vertical-align: initial;
}

.chicken-box-lg .table tr td {
  color: #fff;
  border: 0 !important;
  padding-top: 5px;
  padding-bottom: 40px;
}

/***** Racing Pending *****/

.go-back {
  background: #1083ff !important;
  color: #fff !important;
  border-radius: 5px;
  font-size: 15px;
  padding: 8px 26px;
  /* font-weight: bold; */
  text-decoration: none;
  cursor: pointer;
}

.footer {
  background: var(--nav-bg);
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer ul {
  list-style-type: none;
  padding-left: 0;
}
.footer .footer-link li {
  margin-right: 25px;
}
.footer .social-item {
  float: right;
  margin-top: 10px;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer ul li a {
  color: #000000;
}
.footer .footer-link {
  margin-top: 20px;
  text-align: center;
  color: var(--text);
}

.footer .footer-link a {
  color: var(--text);
}

.footer .footer-link a:hover {
  text-decoration: underline;
}

/* .modal {
    text-align: center;
    padding: 0!important;
} */

/* .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
} */

/* .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
} */

.modal-header .close {
  opacity: 1 !important;
}

.confetti {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  user-select: none;
}

@media (min-width: 768px) {
  .ten-columns > .col-sm-2 {
    width: 20%;
    padding-left: 5px;
    padding-right: 5px;
  }
}


.view-chicken.modal-dialog {
  width: 900px !important;
}

.view-chicken.modal-dialog .modal-header {
  border-bottom: 0;
  text-align: center;
  padding-bottom: 0;
}

/***** Racing Pending *****/

/***** Results *****/

.result-filter {
  padding: 30px 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.result-filter .result-text {
  font-size: 25px;
  vertical-align: middle;
  margin-right: 10px;
  font-family: "Montserrat-SemiBold";
  color: var(--text);
}

.result-filter .btn-filters {
  background: #dd5859 !important;
  border-color: #dd5859;
  border-radius: 5px;
  color: #fff;
  width: 130px;
  padding: 12px 8px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.result-filter .race-result {
  background: #5b6fb6;
  border-radius: 5px;
  padding: 4px 20px;
  float: left;
  margin-right: 10px;
}

.result-filter .race-result h4 {
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.filter-right {
  float: left;
  display: flex;
}

.width100 {
  width: 100%;
}

.result-filter .race-result h4 .yellow {
  color: #ffe500;
}

.result-filter .race-result h4 .green {
  color: #8bf900;
}

.switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  width: 46px;
  height: 11px;
  padding: 3px;
  border-radius: 18px;
  cursor: pointer;
}

.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 10px;
  text-transform: uppercase;
  background: #d8d6d6;
  border-radius: inherit;
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: opacity background;
  -moz-transition-property: opacity background;
  -o-transition-property: opacity background;
  transition-property: opacity background;
}

.switch-label:before,
.switch-label:after {
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #aaa;
}

.switch-label:after {
  content: attr(data-on);
  left: 11px;
  color: white;
  opacity: 0;
}

.switch-input:checked ~ .switch-label {
  background: #47a8d8;
}

.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}

.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}

.switch-handle {
  position: absolute;
  top: 1px;
  left: 4px;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}

.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 12px;
  background: #f9f9f9;
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  background-image: -webkit-linear-gradient(top, #eeeeee, white);
  background-image: -moz-linear-gradient(top, #eeeeee, white);
  background-image: -o-linear-gradient(top, #eeeeee, white);
  background-image: linear-gradient(to bottom, #eeeeee, white);
}

.switch-input:checked ~ .switch-handle {
  left: 28px;
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}

.notification,
.my-chickens-only {
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 10px;
  flex: 1;
}

.notification {
  flex: none;
}

.notification .switch,
.my-chickens-only .switch {
  margin-top: 5px;
}

.my-chicken {
  font-family: "Montserrat-Medium";
  font-size: 13px;
  line-height: 16px;
  color: var(--text);
  margin-left: 10px;
}

.text-class-a {
  padding: 7px 12px;
}

#view-result .modal-dialog {
  width: 95%;
  margin: auto;
}

#view-result .modal-dialog .modal-content {
  background: linear-gradient(rgba(53, 162, 22, 0.14), rgba(53, 162, 22, 0.14)),
    url("../images/banner.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

#view-result .modal-header {
  border: 0;
  padding-bottom: 0;
}

#view-result .modal-header .close {
  opacity: 1;
}

#view-result .modal-body {
  padding-top: 0;
}

/***** Results *****/

.starts-in {
  font-size: 15px;
  color: var(--text);
  margin-left: 10px;
}

.starts-in img {
  height: 18px;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: text-bottom;
}

/************ Extras ***********/

.btnStyle {
  transform: translateZ(0) !important;
  backface-visibility: hidden !important;
  transition: 300ms ease-out !important;
}

.btnStyle:hover {
  transform: scale(0.9) !important;
}

.btnStyle2 {
  transform: translateZ(0) !important;
  backface-visibility: hidden !important;
  transition: 300ms ease-out !important;
}

.btnStyle2:hover {
  transform: scale(1.1) !important;
}

.white {
  color: #fff !important;
}

.whiteMontbold {
  color: #fff;
  font-weight: bold;
}

.block {
  display: block;
}

.dropdown-menu-form {
  max-height: 250px;
  overflow-y: auto;
  width: 100%;
  padding-left: 20px;
}

.select-checkout {
  border: 1.2px solid var(--label);
  box-sizing: border-box;
  border-radius: 5px;
}

.select-checkout a,
.select-checkout button {
  font-size: 14px;
  color: var(--label);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-checkout.show .dropdown-toggle::after {
  transform: rotate(180deg);
}

.btn-filters,
.btn-clear-all-mobile {
  background: #dd5859 !important;
  border-color: #dd5859;
  border-radius: 5px;
  color: #fff;
  width: 110px;
  height: 40px;
  padding: 10px 8px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.btn-filters:hover,
.btn-clear-all-mobile:hover {
  background: var(--hover-danger) !important;
  color: #fff !important;
}

.btn-clear-all-mobile {
  display: none;
}

.btn-coop {
  background: #1083ff !important;
  border-color: #1083ff;
  border-radius: 5px;
  color: #fff;
  width: 110px;
  height: 40px;
  font-size: 14px;
  transition: 300ms ease-out !important;
}

.btn-coop:hover {
  background: var(--hover-blue) !important;
  color: #fff !important;
}

.my-modal-md {
  max-width: 600px;
}

.btn-login {
  background: #449eff !important;
  border-radius: 7px;
  padding: 8px 25px !important;
  margin-top: 0px;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 18px;
  color: #fff !important;
  white-space: nowrap;
}

.btn-watch {
  background: #ffac0b !important;
  border-radius: 5px;
  padding: 8px 25px !important;
  margin-top: 0px;
  font-size: 16px;
  color: #fff !important;
  margin-right: 10px;
  font-weight: 600 !important;
  border-color: #ffac0b;
}

.btn-watch:hover {
  background: var(--hover-yellow) !important;
  color: #fff !important;
}

.loading-main {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--table);
}

.race-result-container {
  display: flex;
  align-items: center;
}

.btn-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.race-result > h4 {
  margin: auto 0;
  padding: 4px;
}

.announcement {
  text-align: center;
  color: red;
  font-size: 20px;
  background: #f5e96b;
  margin-bottom: 0px;
  padding: 10px;
}

.tournaments-banner .tournament {
  text-align: center;
  font-size: 17px;
  background: var(--tournament);
  padding: 10px;
  color: var(--text) !important;
}

.tournaments-banner .tournament.white {
  background: var(--nav-bg);
}

.tournaments-banner .tournament a {
  cursor: pointer;
  text-decoration: underline;
  color: var(--text);
}

.tournaments-banner .tournament a:hover {
  text-decoration: underline;
}

.terrain-image {
  max-width: 70px;
  height: auto;
}

.name-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.name-column-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
}

.name-column-wrap img {
  width: 20px;
}

.copy-icon {
  color: var(--text);
}

.copy-icon span {
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
  margin-top: 5px;
}

.race-row {
  position: relative;
}

.race-row.assigned::before {
  content: "";
  height: 100%;
  width: 10px;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, #1083ff 20%, rgb(223, 241, 249, 0) 100%);
  position: absolute;
}

.race-row.assigned.multiple::before {
  background: #1083ff;
}

.chicken-image {
  background: var(--loading);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin: auto;
}

.chicken-image .img-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.chicken-image .img-container img {
  width: 100%;
  height: 100%;
}

.home .btn-play {
  background: #449eff !important;
  border-radius: 7px;
  padding: 5px 25px !important;
  font-family: "Roboto";
  font-weight: 300;
  font-size: 18px;
  color: #fff !important;
}

.loading-column {
  height: 17px;
  width: 100%;
  margin: auto;
  border-radius: 3px;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(
    90deg,
    rgba(203, 203, 203, 1) 0%,
    rgba(211, 211, 211, 1) 40%,
    rgba(250, 250, 250, 1) 50%,
    rgba(211, 211, 211, 1) 60%,
    rgba(203, 203, 203, 1) 100%
  );
  background-size: 1000px 640px;

  position: relative;
}

.dark .loading-column {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.2) 40%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0.3) 60%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background-size: 1000px 640px;
}

.loading-column.loading-terrain {
  width: 70px;
  height: 40px;
}

.loading-column.loading-pecking-order {
  width: 35px;
  height: 30px;
  border-radius: 4px;
}

.loading-column.loading-button {
  width: 100px;
  height: 40px;
}

.loading-column.loading-small {
  width: 50px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.text-dark {
  color: var(--text-light) !important;
}

.grecaptcha-badge {
  opacity: 0;
}

.sortable {
  white-space: nowrap;
}

.sortable .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 6px dashed;
  border-top: 6px solid\9;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.sortable .dropup .caret {
  content: "";
  border-top: 0;
  border-bottom: 6px dashed;
  border-bottom: 6px solid\9;
}

.table-responsive table {
  font-size: 14px;
}

.star,
.upward,
.downward {
  white-space: nowrap;
}

.star::after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: "\f005";
  color: #ffd700;
  margin-left: 3px;
}

.downward::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px dashed;
  border-top: 6px solid\9;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  margin-bottom: 2px;
  color: red;
  margin-left: 3px;
}

.upward::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-bottom: 6px dashed;
  border-bottom: 6px solid\9;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  color: #27b18a;
  margin-bottom: 2px;
  margin-left: 3px;
}

.gy-1 {
  row-gap: 4px;
}

.gy-2 {
  row-gap: 8px;
}

.gy-3 {
  row-gap: 12px;
}

.gy-4 {
  row-gap: 16px;
}

.gy-5 {
  row-gap: 20px;
}

.theme-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b8cee2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.theme-slider svg {
  position: absolute;
  z-index: 2;
  top: 4px;
}

.theme-slider svg:first-child {
  left: 4px;
}

.theme-slider svg:last-child {
  right: 4px;
}

.theme-slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 0px;
  bottom: 0px;
  background-color: #1283ff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  z-index: 1;
}

input:checked + .theme-slider {
  background-color: #2a353f;
}

input:checked + .theme-slider:before {
  transform: translateX(25px);
}

/* Rounded sliders */
.theme-slider.round {
  border-radius: 34px;
}

.theme-slider.round:before {
  border-radius: 50%;
}

.theme-slider .sun {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 3px;
  top: 4px;
  user-select: none;
}

.theme-slider .moon {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 3px;
  top: 4px;
  user-select: none;
}

.form-group {
  margin-bottom: 10px;
  font-size: 14px;
}

.form-group input {
  font-size: 14px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--label);
  opacity: 1; /* Firefox */
}

.form-control {
  border: 1.2px solid #666666;
  color: var(--text) !important;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36px;
  font-size: 14px;
  background: var(--input) !important;
}

.form-control input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--label);
  opacity: 1; /* Firefox */
}

.form-group label {
  color: var(--label);
  font-weight: lighter;
  margin-bottom: 5px;
}

.footer-version {
  position: fixed;
  bottom: 0;
  right: 15px;
  font-size: 10px;
  color: var(--text);
}

.loading-spinner {
  display: inline-block;
  position: relative;
}
.loading-spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px;
  border: 8px solid #1083ff;
  border-radius: 50%;
  animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1083ff transparent transparent transparent;
}
.loading-spinner.green div {
  border: 8px solid #2ecc71;
  border-color: #2ecc71 transparent transparent transparent;
}

.loading-spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-spinner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.justify-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.btn {
  box-shadow: none !important;
  outline: none !important;
}

.edit-name {
  font-size: 20px;
  vertical-align: middle;
  cursor: pointer;
}

.popup-content {
  width: max-content !important;
  background-color: var(--modal) !important;
  color: var(--text);
  border: 0 !important;
  overflow: hidden;
  padding: 0 !important;
}

.popup-content .popup-arrow {
  color: var(--modal) !important;
  stroke: var(--modal) !important;
}

.popup-content .chicken-info {
  width: 120px;
  background-color: #2196f3;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.popup-content .chicken-info .info {
  margin: 2px 2px;
}

.popup-content .menu-item {
  padding: 5px 10px;
  cursor: pointer;
  text-align: center;
}

.popup-content .menu-item:hover {
  background-color: var(--select);
}

.coop-name-modal .footer-btn {
  padding-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.coop-name-modal .footer-btn button {
  flex: 1;
}
.coop-name-modal .footer-btn .btn-reset {
  margin-right: 0px;
  margin-left: 10px;
}
/*
.chicken-tooltip tbody tr td:first-child {
    text-align: right;
    padding-right: 10px;
} */

.discord-btn {
  background-color: rgb(98, 123, 243);
  border-color: rgb(98, 123, 243);
  border-radius: 5px;
  color: #fff;
  height: 40px;
  padding: 10px 8px;
  font-size: 14px;
  transition: 300ms ease-out !important;
  border: 0;
}

.chicken-name-image {
  display: flex;
  align-items: center;
}

.chicken-name-image .chicken-image,
.chicken-name-image .chicken-preview {
  margin: 0 !important;
  min-width: 35px;
  min-height: 35px;
}

.captcah-error {
  color: red !important;
  margin-bottom: 0px !important;
}

.basic-auth-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basic-auth {
  max-width: 400px;
  width: 100%;
  margin: 20px;
  padding: 20px 40px;
  border-radius: 10px;
  background-color: var(--table);
}

.basic-auth .logo {
  margin: auto;
  width: 140px;
  display: block;
  margin-bottom: 30px;
}

.basic-auth label {
  color: var(--text);
}

.basic-auth .btn-login {
  background: #449eff !important;
  border-radius: 7px;
  padding: 8px 25px !important;
  font-size: 16px;
  color: #fff !important;
  font-weight: bold !important;
  width: 100%;
}

.row .items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-0 {
  flex: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.prompt-modal .modal-content .modal-header {
  align-items: center;
  justify-content: center;
  border-bottom: 0;
}

.prompt-modal .modal-content .modal-body {
  text-align: center;
}

.prompt-modal .modal-content .modal-body p {
  margin-bottom: 0;
}

.prompt-modal .modal-content .modal-footer {
  border-top: 0;
  justify-content: center;
}

.prompt-modal .modal-content .modal-footer button {
  min-width: 70px;
  color: white;
}

.btn-danger {
  background-color: #dd5859;
  font-size: 14px;
}

.btn-primary {
  background-color: #1083ff;
  font-size: 14px;
}

.chicken-preview {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background: #d6d6d6;
  margin: auto;
}

.chicken-preview .chicken-preview-image,
.chicken-preview canvas,
.chicken-preview img {
  position: absolute;
  left: 0;
  border-radius: 5px;
  width: 100% !important;
  height: 100% !important;
  margin-left: initial !important;
  margin-top: initial !important;
  top: 0;
}

.weth-icon {
  width: 13px;
  margin-right: 5px;
}

.react-bs-table-no-data {
  text-align: center !important;
}

a:hover {
  text-decoration: initial;
}

.terms-of-service {
  flex: 1;
  padding: 20px 0;
  background-color: var(--table);
  color: var(--text);
}

.terms-of-service h4 {
  text-align: center;
  font-size: 18px;
  margin-top: 40px;
}

.fusion-page {
  position: relative;
  padding: 0 10px;
}

.fusion-game {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.fusion-game canvas {
  width: 100% !important;
  height: auto !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}

.fusion-game .fusion-game-scroll {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.lock-traits-modal.modal-dialog {
  max-width: 630px;
}

.lock-traits-modal.modal-dialog .modal-header {
  border: 0;
  text-align: center;
  padding-bottom: 0px;
  padding-top: 30px;
}

.lock-traits-modal.modal-dialog .modal-header .modal-title {
  color: var(--modal-title);
  font-size: 16px;
  font-family: "Montserrat-SemiBold";
  margin-bottom: 5px;
}

.lock-traits-modal.modal-dialog .modal-header .close {
  margin-right: 0;
}

.lock-traits-modal.modal-dialog .modal-content .modal-body {
  overflow: auto;
}
.lock-traits-modal.modal-dialog .modal-content .modal-body table {
  width: 100%;
}

.lock-traits-modal.modal-dialog .modal-content .modal-body table tr th,
.lock-traits-modal.modal-dialog .modal-content .modal-body table tr td {
  padding: 2px 10px;
  text-align: center;
  color: var(--text);
  width: 33.33%;
}

.lock-traits-modal.modal-dialog
  .modal-content
  .modal-body
  table
  tr
  th
  .fa-lock {
  font-size: 30px;
}

.lock-traits-modal.modal-dialog
  .modal-content
  .modal-body
  table
  tr
  td.group-title {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  padding-top: 10px;
}

.lock-traits-modal.modal-dialog
  .modal-content
  .modal-body
  table
  tr
  td
  .trait-item {
  justify-content: flex-end;
}

.lock-traits-modal.modal-dialog
  .modal-content
  .modal-body
  table
  tr
  td
  .trait-item.left {
  justify-content: start;
}

.lock-traits-modal.modal-dialog
  .modal-content
  .modal-body
  table
  tr
  td
  .trait-item
  .trait-serums {
  width: 20px;
}

.lock-traits-modal.modal-dialog
  .modal-content
  .modal-body
  table
  tr
  td
  .serums-status {
  height: 30px;
  position: relative;
  width: 100px;
  background: var(--table);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 5px;
  margin-top: 10px;
}

.lighter {
  background-color: var(--table);
}

.feedback img {
  width: 100%;
  height: auto;
}

.feedback .youtube-icon {
  max-width: 208px;
  margin: auto;
  display: block;
}

.chicken-toilet img {
  max-width: 300px;
  height: auto;
}

.chicken-rocket img {
  max-width: 400px;
  height: auto;
}

.steps {
  background: var(--modal);
  border-radius: 15px;
  padding: 15px 25px;
  padding-bottom: 15px;
  margin-bottom: 25px;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}

.steps .step {
  color: var(--text-blue);
  text-align: center;
  font-family: "Chyletoon-Condensed";
  font-size: 46px;
  font-weight: bold;
  margin-bottom: 16px;
}

.get-chicken .info-container,
.race-chicken .info-container {
  display: flex;
  flex-direction: column;
}

.text-green {
  color: var(--hover-green) !important;
}

.text-error {
  color: red !important;
}

@media only screen and (max-width: 767px) {
  .get-chicken .chicken-img {
    border-radius: 20px;
    width: 100%;
  }
  .race-chicken .chicken-img {
    width: 100%;
  }
  .race-chicken .home-list p {
    padding-right: 0;
  }
  .race-chicken .home-list h4 {
    margin-top: 10px;
  }
  .get-chicken .home-list h4 {
    margin-top: 10px;
  }
  .get-chicken .home-list .mt-heading {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .get-chicken .home-list h1 {
    margin-bottom: 0;
    margin-top: 0;
  }
  .get-chicken .home-list p {
    margin-bottom: 10px;
  }

  .discord-bottom a {
    position: absolute;
    right: 0;
    top: -20px;
  }

  .btn-filters {
    width: 90px;
  }

  .table-box ul li {
    display: inline;
    margin-right: 0;
    margin-left: 10px;
  }
  .table-box ul li a {
    font-size: 13px;
  }
  .table-box ul {
    padding-bottom: 14px;
  }
  .table-box .table tr th {
    padding: 10px 10px !important;
  }
  .table-box .table tr td {
    padding: 6px 8px !important;
    font-size: 13px;
  }
  .table-box .reset-expansion-style .table tr td {
    padding: 7px 10px !important;
  }

  .choose-an-account .modal-dialog {
    width: 95%;
  }
  .select-chicken.modal-dialog {
    width: 100%;
    margin: 30px auto;
  }
  .select-chicken .modal-content {
    padding: 10px 5px;
  }
  .select-chicken .order-table tr td {
    text-align: center;
  }
  .transaction-confirmation.modal-dialog {
    width: 95%;
  }
  .transaction-confirmation.modal-dialog .modal-content {
    padding: 0 0px;
  }
  .block {
    display: inline;
  }
  .chicken-box-lg .table-responsive {
    border: 0 !important;
  }
  .chicken-box-lg {
    padding: 20px 10px;
  }
  .chicken-box-lg .table tr th {
    font-size: 14px;
  }

  .chicken-box-listing .table tr th {
    line-break: anywhere;
  }

  .chicken-box-lg .table tr td {
    padding-bottom: 20px;
    font-size: 12px !important;
  }
  .view-chicken.modal-dialog {
    width: 100% !important;
  }

  .filter-popup.modal-dialog {
    max-width: 100% !important;
    margin: 30px auto;
    width: 90%;
  }
  .select-chicken .select-chicken-row {
    display: block;
    text-align: center;
  }
  .select-chicken .select-chicken-row div {
    margin-bottom: 10px;
  }
  .table-box .reset-expansion-style .pick-gate {
    max-width: 350px;
  }
  .table-box .reset-expansion-style .pick-gate .open-gates span {
    display: block;
    margin-right: 0;
  }
  .table-box .reset-expansion-style .pick-gate .open-gates .btn {
    font-size: 15px;
    padding: 6px 30px;
    display: block;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .table-box .reset-expansion-style .pick-gate h2 {
    margin-bottom: 10px;
  }
  .table-box .reset-expansion-style .pick-gate .open-gates span {
    margin-bottom: 15px;
  }
  .table-box .reset-expansion-style .pick-gate {
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-nav .open .dropdown-menu {
    text-align: center;
  }
  .my-chickens-top {
    display: block;
    text-align: center;
  }
  .result-filter {
    padding: 30px 10px;
    display: block;
    text-align: center;
  }
  .result-filter .race-result {
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .filter-right {
    float: none;
  }
  .table-buttons {
    padding: 10px !important;
  }
  .filter-btns .buttons {
    width: 100%;
  }
  .filter-btns .buttons button {
    width: 100%;
  }
  .filter-btns .btn-clear-all {
    display: none !important;
  }
  .filters-btns {
    margin-top: 0.5rem !important;
  }
  .filters-btns .btn-clear-all-mobile {
    display: block !important;
  }
  .filters-btns .btn {
    flex: 1;
  }
  .filter-actions {
    flex-direction: column;
    margin-top: 0px !important;
  }
  .filter-actions .my-chickens-only {
    margin-bottom: 20px;
  }
  .filter-actions .my-chickens-only .switch {
    margin-left: 0;
  }
  .result-filter {
    flex-direction: column;
    padding: 10px !important;
  }
  .result-filter .my-chickens-only {
    padding: 10px 0;
  }
  .result-filter .my-chickens-only .switch {
    margin-left: 0px;
  }
  .result-filter .race-result-container {
    flex-direction: column;
  }
  .result-filter .race-result-container .race-result {
    padding: 4px 10px;
    width: 100%;
  }
  .result-filter .race-result-container .race-result h4 {
    font-size: 14px;
  }
  .result-filter .race-result-container .filter-right {
    width: 100%;
  }
  .result-filter .race-result-container .filter-right .btn {
    flex: 1;
  }
  .result-filter .race-result-container .filter-right .btn-clear-all-mobile {
    display: block;
  }
  .offer-metamask .offer-metamask-action .btn {
    margin: 0px !important;
    width: 70%;
  }
  .offer-metamask .offer-metamask-action .btn:first-child {
    margin-bottom: 5px !important;
  }
  .tournaments-banner .tournament {
    font-size: 13px !important;
  }
  .footer {
    text-align: center;
  }
  .footer .footer-logo {
    display: none;
  }
  .footer .footer-link {
    padding-top: 0px;
  }
  .footer .footer-link li {
    margin-right: 0px;
    display: block;
    margin-bottom: 10px;
  }
  .footer .social-item {
    float: none;
    margin-top: 10px;
  }

  .my-coops {
    overflow: initial;
  }

  .my-coops .coop-boxes {
    display: none;
  }

  .my-coops .coop-dropdown {
    display: block;
  }

  .bridge-modal .modal-content .modal-body iframe {
    height: calc(100vh - 160px);
  }

  .my-chickens .filter-btn-wrap {
    flex-direction: column;
    row-gap: 10px;
  }
  .my-chickens .filter-btn-wrap * {
    margin-right: 0;
  }
  .my-chickens .filter-btn-wrap > * {
    flex: 1 1;
    width: 100%;
    margin: 0;
  }

  .chicken-detail-container {
    flex-direction: column;
    gap: 10px !important;
  }

  .chicken-detail .chicken-detail-container .chicken-main .chicken-main-header {
    justify-content: center;
    flex-wrap: wrap;
    height: initial;
    margin: 10px 0;
  }

  .fusion-game canvas {
    width: unset !important;
    height: unset !important;
  }

  .mint-serum .mint-serum-warp {
    flex-direction: column;
  }

  .feedback img {
    width: 280px;
  }

  .chicken-toilet img {
    max-width: 184px;
  }

  .chicken-rocket img {
    max-width: 250px;
  }
}

@media (max-width: 991px) {
  .my-chickens .filter-btn-wrap .btn {
    flex: 1;
  }

  .container {
    max-width: none;
  }

  .chicken-toilet img {
    max-width: 250px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .table-box .table tr th {
    padding: 15px 10px !important;
    font-size: 13px;
  }
  .table-box .table tr td {
    padding: 15px 10px !important;
    font-size: 13px;
  }
  .coop-box {
    padding: 10px 10px;
  }
  .ten-columns > .col-sm-2 {
    width: 33% !important;
  }
  .result-filter {
    padding: 30px 5px;
  }
  .race-chicken .chicken-img {
    width: 100%;
  }
  .get-chicken .chicken-img {
    width: 100%;
  }
  .get-chicken .home-list h1 {
    margin-top: 0;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .get-chicken .home-list p {
    line-height: 26px;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .race-chicken .home-list h1 {
    margin-top: 0;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .race-chicken .home-list p {
    padding-right: 0;
    margin-bottom: 15px;
  }
  .block {
    display: inline;
  }

  .build-box .d-flex {
    align-items: center;
    display: block !important;
    text-align: center;
  }
  .build-box img {
    margin-bottom: 10px;
  }
  .build-box {
    padding: 15px 5px;
    height: calc(100% - 25px);
  }
  .footer .footer-link li {
    margin-right: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .coop-box ul li {
    padding: 7px 17px;
  }
  .coop-box .btn-showdetails {
    padding: 15px 15px;
    font-size: 15px;
  }
  .race-chicken .chicken-img {
    width: 100%;
  }
  .get-chicken .chicken-img {
    width: 100%;
  }
  .build-box {
    padding: 15px 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1420px;
  }
}

@media (min-width: 1480px) and (max-width: 5120px) {
  .overlay-bg {
    background-size: cover;
  }
  #view-result .modal-dialog {
    width: 75%;
    margin: auto;
  }
}

@media (max-width: 576px) {
  .race-chicken .home-list p,
  .get-chicken .home-list p,
  .build-right p {
    font-weight: bolder;
  }

  .get-chicken .chicken-img {
    margin-bottom: 20px;
  }

  .race-chicken .chicken-img {
    margin-bottom: 20px;
  }

  .get-chicken .img-container,
  .race-chicken .img-container {
    order: 1;
  }

  .get-chicken .info-container,
  .race-chicken .info-container {
    order: 2;
    margin-top: 32px;
  }

  .build-box .flex-shrink-0 {
    width: 80px;
  }

  .build-box img {
    max-width: 100%;
  }

  .table-box ul {
    padding-bottom: 5px;
  }

  .table-box ul li {
    padding-bottom: 10px;
  }

  .table-box ul li:last-child {
    padding-bottom: 5px;
  }

  .table-box ul li a {
    padding-bottom: 5px;
  }

  .table-section {
    position: relative;
  }
}

.fusion-page {
  flex: 1;
  background-image: url("../images/fusion/fusion_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.fusion-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.fusion-canvas canvas {
  height: 100% !important;
  width: auto !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.fusion-canvas.revert {
  align-items: flex-start;
}

.fusion-canvas.revert canvas {
  height: auto !important;
  width: 100% !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.fusion-page .fusion-huh-container {
  margin: auto;
  position: relative;
}

.fusion-page .huh-button {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.fusion-page .huh-button img {
  width: 100%;
  user-select: none;
  /* height: 100%; */
}

.fusion-page .huh-button img.flipped {
  transform: scaleX(-1);
}

.table-sort-caret {
  display: inline;
  position: relative;
  height: 18px;
  margin-left: 3px;
}

.table-sort-caret svg {
  height: 9px;
  position: absolute;
}

.table-sort-caret svg:first-child {
  top: -1px;
}

.table-sort-caret svg:last-child {
  transform: rotate(-180deg);
  bottom: -1px;
}

.join-our-community {
  background-color: var(--table);
}

#summon canvas {
  width: auto !important;
  height: auto !important;
  max-width: 100vw;
  max-height: 100vh;
  margin-left: 0px !important;
  margin-top: 5px !important;
}

#world-map canvas {
  width: auto !important;
  height: auto !important;
  max-width: 100vw;
  max-height: 100vh;
}

.world-map-wrap,
.summon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.currency-symbol {
  text-transform: uppercase !important;
  margin: 0 !important;
}

.currency-symbol.gray {
  filter: grayscale(100%);
}

#overall-performance.show canvas,
#distance-performance.show canvas,
#terrain-performance.show canvas {
  width: 100% !important;
  height: auto !important;
}
