.step-bar {
  position: relative;
  margin: auto;
}

.step-bar .step-bar-image {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.step-bar .step-area {
  position: absolute;
  overflow: hidden;
}

.step-bar .step-progress {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.step-bar .step-line {
  position: absolute;
  z-index: 2;
}
