.road-maps {
  padding: 50px 0 30px;
  /* background-color: var(--home-bg); */
}

.road-maps-container {
  max-width: 1920px;
  margin: auto;
  position: relative;
}

.road-maps .road-maps-title {
  position: relative;
  font-family: 'Chyletoon-Condensed';
  color: var(--text-blue);
  margin-top: 0px;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-align: center;
}

.road-maps .road-maps-unlocked-count {
  color: var(--text-blue);
  text-align: center;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 16px;
}

.road-maps-content {
  display: flex;
  overflow-y: hidden;
}

.road-maps-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.road-maps-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.road-map-container {
  margin: 0 10px;
  position: relative;
  color: white;
  border-radius: 20px;
  overflow: hidden;
  border: solid 1px #449EFF;
  z-index: 1;
  height: 350px;
  width: 270px;
  min-width: 270px;
}

.road-map-container .bg-image {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: bottom left;
  background-image: url("../../assets/images/road-map.png");
  z-index: 1;
}

.road-map-container.locked .bg-image {
  filter: grayscale(100%) blur(4px);
}

.road-map {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: grab;
  position: absolute;
  z-index: 2;
  background-image: linear-gradient(transparent, transparent, rgba(0,0,0,0.7));
}

.road-map-header {
  display: flex;
  align-items: center;
  align-items: center;
  padding: 7px 15px;
}

.road-map-title {
  margin: 0;
  flex: 1;
  text-transform: uppercase;
}

.road-map-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 15px;
  position: relative;
  padding-bottom: 20px;
}

.road-map-content {
  display: flex;
  gap: 10px;
  min-height: 72px;
}

.road-map-icons img {
  width: 30px;
  margin-top: 10px;
}

.road-map-content p {
  margin: 0;
}

.road-map-headline {
  font-family: 'Chyletoon-Condensed';
  line-height: 30px;
  margin: 0;
}

.road-map-timeline {
  text-transform: uppercase;
  font-size: 14px;
}

.road-map-summary {
  font-size: 14px;
  opacity: 0.8;
}

.lock-icon {
  font-size: 40px;
  text-align: center;
  top: 80px;
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
}

.road-map-container a {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  cursor: pointer;
}

.road-maps-scroll {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  overflow: auto;
  cursor: pointer;
  margin: auto;
  margin-top: 20px;
  position: relative;
}

.cross_arrow {
  position: absolute;
  width: 40px;
  left: calc(50% + 100px);
  bottom: -10px;
}

.road-maps-scroll::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.road-maps-scroll::-webkit-scrollbar-track {
  background: #599EF840;
  border-radius: 10px;
}

/* Handle */
.road-maps-scroll::-webkit-scrollbar-thumb {
  background: #599EF8;
  border-radius: 10px;
}
