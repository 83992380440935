.date-picker-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-picker-container .date-picker-input {
  border: 1.2px solid #666666;
  color: var(--text) !important;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36px;
  font-size: 14px;
  background: var(--input) !important;
  width: 100%;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
}

.date-picker-container .date-picker-input span {
  color: #6c757d;
  opacity: 1; /* Firefox */
}

.date-picker-container .date-picker-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--modal);
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
}

.date-picker-container .date-picker-modal.show {
  display: block;
}

.date-picker-container .date-picker-modal .date-picker-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  gap: 10px;
}

.date-picker-container .date-picker-modal .date-picker-actions .date-picker-btn {
  border: 1px solid;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
}

.date-picker-container .date-picker-modal .date-picker-actions .clear-btn {
  border-color: #DD5859;
  color: #DD5859;
}

.date-picker-container .date-picker-modal .date-picker-actions .select-btn {
  border-color: #1083FF;
  color: #1083FF;;
}

.date-picker-modal .rdrCalendarWrapper {
  background: var(--modal);
}

.date-picker-modal .rdrDateDisplayWrapper {
  background: var(--modal);
}


.rdrInputRanges .rdrInputRange input,
.rdrDateDisplay .rdrDateInput input {
  border: 1.2px solid #666666;
  color: var(--text) !important;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36px;
  font-size: 14px;
  background: var(--input) !important;
}

.rdrInputRanges .rdrInputRange input:focus,
.rdrDateDisplay .rdrDateInput input:focus {
  border-color: #1083FF;
}
.rdrDayPassive {
  color: #758187 !important;
  opacity: 0.8;
}

.rdrDateDisplayItem {
  background-color: inherit;
  box-shadow: inherit;
  border: 0;
}

.rdrDefinedRangesWrapper {
  background: var(--modal);
  color: var(--text);
}

.rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel {
  background: var(--modal);
}
.rdrStaticRange {
  background: var(--modal);
  color: var(--text);
}
.rdrStaticRange:hover {
  background: var(--modal) !important;
}
.rdrDayNumber span {
  color: var(--text);
}

.rdrMonthAndYearPickers select {
  color: var(--text-light);
}
