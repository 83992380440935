.custom-accordion .card {
  background-color: transparent;
  border-radius: 15px;
  border: none;
  margin-bottom: 12px;
}

.custom-accordion .card-header, .custom-accordion .card-body {
  background-color: var(--modal);
  color: var(--text);
  font-family: 'Roboto';
  font-weight: 300;
}

.custom-accordion .card-header {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  cursor: pointer;
  font-size: 20px;
}

.custom-accordion .accordion-active .card-header {
  position: relative;
  border-radius: 15px 15px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.custom-accordion .accordion-icon {
  position: absolute;
  right: 15px;
  transition: all 0.3s;
}

.custom-accordion .accordion-icon .fa-chevron-right {
  color: var(--label);
  font-size: 12px;
  font-weight: 500;
}

.custom-accordion .card-body {
  display: none;
}

.custom-accordion .accordion-active .card-body {
  display: block;
  font-size: 20px;
  border-radius: 0 0 15px 15px;
  border-top: none;
  border-left: 1px solid rgba(0, 0, 0, 0.10);
  border-right: 1px solid rgba(0, 0, 0, 0.10);
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.accordion-active .accordion-icon {
  transform: rotate(90deg);
}

@media (max-width: 576px) {
  .custom-accordion .card-header,
  .custom-accordion .accordion-active .card-body {
    font-weight: bolder;
  }
}
