.trailer-video {
  background: transparent;
  border: 0;
}

.trailer-video .modal-body {
  padding: 0;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  background: black;
}

.trailer-video .modal-body iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.trailer-video .modal-body .close {
  position: absolute;
  top: -50px;
  right: 0;
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 1;
  opacity: 1;
}
