.home-gif {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.home-gif img {
  max-width: 100%;
  width: 100%;
}

.home-gif img.absolute {
  display: absolute;
  top: 0;
  left: 0;
}
