.trailer-video-container {
  max-width: 700px;
  aspect-ratio: 700/398;
  position: relative;
  margin: 0 auto 30px;
  border: 3px solid var(--modal);
  outline: 3px solid #449EFF;
  padding: 2px;
  border-radius: 30px;
  overflow: hidden;
}

.trailer-video-container.no-border {
  border: 0;
  outline: none;
  border-radius: 20px;
  padding: 0;
  aspect-ratio: 700/359;
}

.trailer-video-container.no-border video {
  width: 100%;
  margin-top: 0;
  margin-left: 0;
}

.trailer-video-container .video-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.trailer-video-container .video-wrap .play-icon {
  position: absolute;
}

.trailer-video-container svg {
  position: absolute;
  z-index: 2;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  top: -5px;
  left: -5px;
}

.trailer-video-container video {
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  margin-top: -5px;
  margin-left: -5px;
}
