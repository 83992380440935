.bawk-header {
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  padding: 30px 0 20px;
}

.bawk-header .bawk-menu {
  display: flex;
  align-items: center;
  gap: 14px;
}

.bawk-header .bawk-btn {
  padding: 6px 20px;
  font-size: 23px;
  line-height: 27px;
  color: white;
  border-radius: 50px;
  border: 1px solid #1283FF;
  background: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 12.199999809265137px 0px #459EFF8A;
  font-weight: 300;
  cursor: pointer;
}

.bawk-header .bawk-btn:hover {
  background-color: #F8EB52;
  border: 1px solid #FFFFFF;
  color: #000000;
}

.bawk-header .bawk-btn:active {
  background: #F8EB5299;
  border: 1px solid #FFFFFF;
  color: #000000;
}

.bawk-header .bawk-btn.yellow {
  background-color: #F8EB52;
  color: black;
}

.bawk-header .bawk-btn.yellow:hover {
  background-color: #52F474;
  border: 1px solid #52F474;
}

.bawk-header .bawk-btn.yellow:active {
  background-color: #F8EB52CC;
  border: 1px solid #F8EB52CC;
}

.bawk-header .bawk-logo {
  width: 150px;
}

.bawk-header-mobile {
  position: relative;
  width: 100%;
}

.bawk-header-mobile.open {
  background-color: #11202ef0;
}

.bawk-header-mobile .bawk-header-top {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.bawk-header-mobile  .bawk-header-top .bawk-logo-mobile {
  max-width: 180px;
}

.bawk-header-mobile  .bawk-header-top .bawk-menu-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bawk-header-mobile .bawk-menu {
  display: none;
}

.bawk-header-mobile .bawk-menu.open {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  background-color: #11202ef0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 100%;
  z-index: 2;
  width: 100%;
}

.bawk-header-mobile .bawk-menu a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 10px;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid white;
}

.bawk-header-mobile .bawk-menu a:last-child {
  border-bottom: 0;
}
